import type { BoxProps } from "@mantine/core";

type CardType = "dark" | "bright" | "light";

export type CardPreviewCustomizationProps = {
  titleFontFamily?: string;
  bodyFontFamily?: string;
};

export function customizeTextVariantIfNeededForCardPreview(
  variant: string,
  fontFamily?: string,
) {
  if (!!fontFamily) {
    return `${variant}-card-preview`;
  }
  return variant;
}

export type BentoCardChildrenProps = {
  imgIndex?: number;
  imgLocked?: boolean;
  uploadedImagePath?: string;
};

export type CardColorScheme = {
  background: string;
  tone: string;
  textdark: string;
  textlight: string;
  ctadark: string;
  ctalight: string;
  textcontrast: string;
};

export type InspoTypeCardProps<T> = {
  cardState?: BentoCardChildrenProps;
  setCardState?: (state: BentoCardChildrenProps) => void;
  colorScheme: CardColorScheme;
  content: T;
} & BoxProps;

export type PaletteType = Record<CardType, CardColorScheme> & {
  slug: string;
  name: string;
  group: string;
};

const palette1: PaletteType = {
  slug: "1",
  name: "Pastel 1",
  group: "Other",
  dark: {
    background: "#B4D8BD",
    tone: "#CDE5D2",
    textdark: "#112216",
    textlight: "#F4F9F4",
    ctadark: "#112216",
    ctalight: "#FCFBEA",
    textcontrast: "#FCFBEA",
  },
  bright: {
    background: "#8EBBBD",
    tone: "#C4DCDD",
    textdark: "#1E2B2E",
    textlight: "#F3F8F8",
    ctadark: "#1E2B2E",
    ctalight: "#FCFBEA",
    textcontrast: "#FCFBEA",
  },
  light: {
    background: "#F9F7CE",
    tone: "#FCFBEA",
    textdark: "#1E2B2E",
    textlight: "#FCFBEA",
    ctadark: "#1E2B2E",
    ctalight: "#FCFBEA",
    textcontrast: "#FCFBEA",
  },
};

const palette2: PaletteType = {
  slug: "2",
  name: "Pastel 2",
  group: "Other",
  dark: {
    background: "#EADFB4",
    tone: "#F3ECD2",
    textdark: "#371E11",
    textlight: "#FBF8EF",
    ctadark: "#371E11",
    ctalight: "#F3ECD2",
    textcontrast: "#F3ECD2",
  },
  bright: {
    background: "#9BB0C1",
    tone: "#DBE6EA",
    textdark: "#2C323A",
    textlight: "#F4F8F9",
    ctadark: "#2C323A",
    ctalight: "#F3ECD2",
    textcontrast: "#F3ECD2",
  },
  light: {
    background: "#51829B",
    tone: "#416379",
    textdark: "#EADFB4",
    textlight: "#1C2630",
    ctadark: "#EADFB4",
    ctalight: "#F3ECD2",
    textcontrast: "#F3ECD2",
  },
};

const palette3: PaletteType = {
  slug: "3",
  name: "Pastel 3",
  group: "Other",
  dark: {
    background: "#FFE6E6",
    tone: "#FECDCF",
    textdark: "#693250",
    textlight: "#FECDCF",
    ctadark: "#693250",
    ctalight: "#FFE6E6",
    textcontrast: "#FFE6E6",
  },
  bright: {
    background: "#E1AFD1",
    tone: "#F0DAEA",
    textdark: "#693250",
    textlight: "#E1AFD1",
    ctadark: "#693250",
    ctalight: "#FFE6E6",
    textcontrast: "#FFE6E6",
  },
  light: {
    background: "#AD88C6",
    tone: "#f4eff8",
    textdark: "#3B2447",
    textlight: "#f4eff8",
    ctadark: "#3B2447",
    ctalight: "#FFE6E6",
    textcontrast: "#FFE6E6",
  },
};

const palette4: PaletteType = {
  slug: "4",
  name: "Pastel 4",
  group: "Other",
  dark: {
    background: "#FEECE2",
    tone: "#FEF4EE",
    textdark: "#410909",
    textlight: "#FEF4EE",
    ctadark: "#410909",
    ctalight: "#FEF4EE",
    textcontrast: "#FEF4EE",
  },
  bright: {
    background: "#F7DED0",
    tone: "#FCF4F0",
    textdark: "#410909",
    textlight: "#FCF4F0",
    ctadark: "#410909",
    ctalight: "#FEF4EE",
    textcontrast: "#FEF4EE",
  },
  light: {
    background: "#E2BFB3",
    tone: "#F0DED8",
    textdark: "#362019",
    textlight: "#FBF7F5",
    ctadark: "#362019",
    ctalight: "#FEF4EE",
    textcontrast: "#FEF4EE",
  },
};

const palette5: PaletteType = {
  slug: "5",
  name: "Vintage 1",
  group: "Other",
  dark: {
    background: "#71705C",
    tone: "#8A8871",
    textdark: "#FFFFFF",
    textlight: "#3D3D32",
    ctadark: "#FFFFFF",
    ctalight: "#8A8871",
    textcontrast: "#FCF6F2",
  },
  bright: {
    background: "#73BEC5",
    tone: "#82D6DE",
    textdark: "#3D3D32",
    textlight: "#F5F5F5",
    ctadark: "#3D3D32",
    ctalight: "#FCF6F2",
    textcontrast: "#FCF6F2",
  },
  light: {
    background: "#E3D0D8",
    tone: "#C9B9C0",
    textdark: "#1F1F1F",
    textlight: "#F5F5F5",
    ctadark: "#1F1F1F",
    ctalight: "#FCF6F2",
    textcontrast: "#FCF6F2",
  },
};

const palette6: PaletteType = {
  slug: "6",
  name: "Vintage 2",
  group: "Other",
  dark: {
    background: "#A05E62",
    tone: "#BA6D72",
    textdark: "#FFE3E5",
    textlight: "#0F0909",
    ctadark: "#FFE3E5",
    ctalight: "#A05E62",
    textcontrast: "#FCF6F2",
  },
  bright: {
    background: "#F7C59F",
    tone: "#E8B995",
    textdark: "#5E4B3D",
    textlight: "#F6EFDD",
    ctadark: "#5E4B3D",
    ctalight: "#FCF6F2",
    textcontrast: "#FCF6F2",
  },
  light: {
    background: "#A9C6F4",
    tone: "#C1D5F4",
    textdark: "#1C2129",
    textlight: "#E8EDF4",
    ctadark: "#1C2129",
    ctalight: "#FCF6F2",
    textcontrast: "#FCF6F2",
  },
};

const palette7: PaletteType = {
  slug: "7",
  name: "Vintage 3",
  group: "Other",
  dark: {
    background: "#1C1464",
    tone: "#23197D",
    textdark: "#F6F0E1",
    textlight: "#3828C9",
    ctadark: "#F6F0E1",
    ctalight: "#3828C9",
    textcontrast: "#FCF6F2",
  },
  bright: {
    background: "#D7E0DF",
    tone: "#BFC7C6",
    textdark: "#232828",
    textlight: "#E8FFF6",
    ctadark: "#232828",
    ctalight: "#E8FFF6",
    textcontrast: "#FCF6F2",
  },
  light: {
    background: "#FDF5DC",
    tone: "#E3DCC5",
    textdark: "#232828",
    textlight: "#F5FFFE",
    ctadark: "#232828",
    ctalight: "#F5FFFE",
    textcontrast: "#FCF6F2",
  },
};

const palette8: PaletteType = {
  slug: "8",
  name: "Vintage 4",
  group: "Other",
  dark: {
    background: "#847465",
    tone: "#6B5E52",
    textdark: "#FAF6EA",
    textlight: "#1F1B17",
    ctadark: "#FAF6EA",
    ctalight: "#1F1B17",
    textcontrast: "#FCF6F2",
  },
  bright: {
    background: "#E8DFCD",
    tone: "#CFC7B7",
    textdark: "#5E4B3D",
    textlight: "#FFFBF2",
    ctadark: "#5E4B3D",
    ctalight: "#FFFBF2",
    textcontrast: "#FCF6F2",
  },
  light: {
    background: "#F9F6EA",
    tone: "#E0DED3",
    textdark: "#635D50",
    textlight: "#F9F9F9",
    ctadark: "#302E27",
    ctalight: "#F9F9F9",
    textcontrast: "#FCF6F2",
  },
};

const palette9: PaletteType = {
  slug: "9",
  name: "Neon 1",
  group: "Other",
  dark: {
    background: "#15F5BA",
    tone: "#97FFDC",
    textdark: "#00352C",
    textlight: "#E8FFF7",
    ctadark: "#00352C",
    ctalight: "#E8FFF7",
    textcontrast: "#E8FFF7",
  },
  bright: {
    background: "#836FFF",
    tone: "#b9b2ff",
    textdark: "#09031C",
    textlight: "#F4F2FF",
    ctadark: "#09031C",
    ctalight: "#F4F2FF",
    textcontrast: "#F4F2FF",
  },
  light: {
    background: "#211951",
    tone: "#482fd3",
    textdark: "#DEE3FF",
    textlight: "#1C2630",
    ctadark: "#DEE3FF",
    ctalight: "#1C2630",
    textcontrast: "#DEE3FF",
  },
};

const palette10: PaletteType = {
  slug: "10",
  name: "Neon 2",
  group: "Other",
  dark: {
    background: "#2C2D2D",
    tone: "#464747",
    textdark: "#808881",
    textlight: "#191A1A",
    ctadark: "#808881",
    ctalight: "#191A1A",
    textcontrast: "#FCF6F2",
  },
  bright: {
    background: "#808881",
    tone: "#97A198",
    textdark: "#2C2D2D",
    textlight: "#EEEEEE",
    ctadark: "#2C2D2D",
    ctalight: "#EEEEEE",
    textcontrast: "#FCF6F2",
  },
  light: {
    background: "#C1F912",
    tone: "#ADE500",
    textdark: "#2C2D2D",
    textlight: "#FFFFA8",
    ctadark: "#2C2D2D",
    ctalight: "#FFFFA8",
    textcontrast: "#FCF6F2",
  },
};

const palette11: PaletteType = {
  slug: "11",
  name: "Neon 3",
  group: "Other",
  dark: {
    background: "#00224D",
    tone: "#004CB3",
    textdark: "#E9FAFF",
    textlight: "#00224D",
    ctadark: "#E9FAFF",
    ctalight: "#00224D",
    textcontrast: "#E9FAFF",
  },
  bright: {
    background: "#A0153E",
    tone: "#BB1541",
    textdark: "#FBA6B2",
    textlight: "#4b061b",
    ctadark: "#FBA6B2",
    ctalight: "#FFF0F2",
    textcontrast: "#FFF0F2",
  },
  light: {
    background: "#FF204E",
    tone: "#FF6984",
    textdark: "#50011A",
    textlight: "#FFF0F2",
    ctadark: "#50011A",
    ctalight: "#FFF0F2",
    textcontrast: "#FFF0F2",
  },
};

const palette12: PaletteType = {
  slug: "12",
  name: "Neon 4",
  group: "Other",
  dark: {
    background: "#49108B",
    tone: "#872AFD",
    textdark: "#EF74F2",
    textlight: "#270654",
    ctadark: "#EF74F2",
    ctalight: "#270654",
    textcontrast: "#F5F2FF",
  },
  bright: {
    background: "#E26EE5",
    tone: "#EB84EE",
    textdark: "#480A45",
    textlight: "#FDF5FE",
    ctadark: "#480A45",
    ctalight: "#FDF5FE",
    textcontrast: "#FDF5FE",
  },
  light: {
    background: "#F3F8FF",
    tone: "#F3F8FF",
    textdark: "#480A45",
    textlight: "#F3F8FF",
    ctadark: "#480A45",
    ctalight: "#F3F8FF",
    textcontrast: "#F3F8FF",
  },
};

const palette13: PaletteType = {
  slug: "13",
  name: "Warm 1",
  group: "Other",
  dark: {
    background: "#FEC7B4",
    tone: "#FFD7C9",
    textdark: "#461909",
    textlight: "#E8FFF7",
    ctadark: "#461909",
    ctalight: "#E8FFF7",
    textcontrast: "#E8FFF7",
  },
  bright: {
    background: "#FFF3C7",
    tone: "#FFE588",
    textdark: "#461902",
    textlight: "#FFFBEB",
    ctadark: "#461902",
    ctalight: "#FFFBEB",
    textcontrast: "#FFFBEB",
  },
  light: {
    background: "#FC819E",
    tone: "#FDA4B7",
    textdark: "#4C051F",
    textlight: "#FFF1F3",
    ctadark: "#4C051F",
    ctalight: "#FFF1F3",
    textcontrast: "#DEE3FF",
  },
};

const palette14: PaletteType = {
  slug: "14",
  name: "Warm 2",
  group: "Other",
  dark: {
    background: "#5B0337",
    tone: "#BD8898",
    textdark: "#FFE1D9",
    textlight: "#420228",
    ctadark: "#FFE1D9",
    ctalight: "#420228",
    textcontrast: "#FCF6F2",
  },
  bright: {
    background: "#E07A5F",
    tone: "#C76C54",
    textdark: "#5B0337",
    textlight: "#EDC1A5",
    ctadark: "#70290F",
    ctalight: "#EDC1A5",
    textcontrast: "#FCF6F2",
  },
  light: {
    background: "#E7DDFE",
    tone: "#D1C8E5",
    textdark: "#4F28B6",
    textlight: "#DED1FF",
    ctadark: "#4F28B6",
    ctalight: "#DED1FF",
    textcontrast: "#FCF6F2",
  },
};

const palette15: PaletteType = {
  slug: "15",
  name: "Warm 3",
  group: "Other",
  dark: {
    background: "#85586F",
    tone: "#996983",
    textdark: "#F4EFF2",
    textlight: "#35222C",
    ctadark: "#F4EFF2",
    ctalight: "#35222C",
    textcontrast: "#F9F6F8",
  },
  bright: {
    background: "#AC7D88",
    tone: "#7D5360",
    textdark: "#FAF6F7",
    textlight: "#312026",
    ctadark: "#FAF6F7",
    ctalight: "#312026",
    textcontrast: "#FFF0F2",
  },
  light: {
    background: "#FDF0D1",
    tone: "#FEF9EE",
    textdark: "#411C09",
    textlight: "#FFF0F2",
    ctadark: "#411C09",
    ctalight: "#FFF0F2",
    textcontrast: "#FFF0F2",
  },
};

const palette16: PaletteType = {
  slug: "16",
  name: "Warm 4",
  group: "Other",
  dark: {
    background: "#502C1C",
    tone: "#693A25",
    textdark: "#E64B37",
    textlight: "#1C0F0A",
    ctadark: "#E64B37",
    ctalight: "#1C0F0A",
    textcontrast: "#FCF6F2",
  },
  bright: {
    background: "#E64B37",
    tone: "#f38476",
    textdark: "#21270c",
    textlight: "#FFDBD6",
    ctadark: "#502C1C",
    ctalight: "#FFDBD6",
    textcontrast: "#FCF6F2",
  },
  light: {
    background: "#EFA748",
    tone: "#D69540",
    textdark: "#993325",
    textlight: "#FFDFB5",
    ctadark: "#993325",
    ctalight: "#FFDFB5",
    textcontrast: "#FCF6F2",
  },
};

const palette17: PaletteType = {
  slug: "17",
  name: "Cold 1",
  group: "Other",
  dark: {
    background: "#353749",
    tone: "#515678",
    textdark: "#D5D7E2",
    textlight: "#22232F",
    ctadark: "#D5D7E2",
    ctalight: "#22232F",
    textcontrast: "#ECEDF2",
  },
  bright: {
    background: "#57717A",
    tone: "#43535B",
    textdark: "#C9D5D8",
    textlight: "#363F43",
    ctadark: "#C9D5D8",
    ctalight: "#363F43",
    textcontrast: "#FFFBEB",
  },
  light: {
    background: "#809F85",
    tone: "#9DB8A0",
    textdark: "#223226",
    textlight: "#E1EAE1",
    ctadark: "#223226",
    ctalight: "#E1EAE1",
    textcontrast: "#E1EAE1",
  },
};

const palette18: PaletteType = {
  slug: "18",
  name: "Cold 2",
  group: "Other",
  dark: {
    background: "#6503A6",
    tone: "#7403BF",
    textdark: "#8BE2FE",
    textlight: "#170126",
    ctadark: "#8BE2FE",
    ctalight: "#170126",
    textcontrast: "#FCF6F2",
  },
  bright: {
    background: "#8BE2FE",
    tone: "#A6E9FF",
    textdark: "#6503A6",
    textlight: "#D8F5FE",
    ctadark: "#6503A6",
    ctalight: "#D8F5FE",
    textcontrast: "#FCF6F2",
  },
  light: {
    background: "#FDE8E9",
    tone: "#E3D0D1",
    textdark: "#803639",
    textlight: "#E9DDD1",
    ctadark: "#803639",
    ctalight: "#E9DDD1",
    textcontrast: "#FCF6F2",
  },
};

const palette19: PaletteType = {
  slug: "19",
  name: "Cold 3",
  group: "Other",
  dark: {
    background: "#1E492B",
    tone: "#337D4A",
    textdark: "#55BA93",
    textlight: "#09170E",
    ctadark: "#55BA93",
    ctalight: "#09170E",
    textcontrast: "#FCF6F2",
  },
  bright: {
    background: "#55BA93",
    tone: "#6CEDBB",
    textdark: "#1E492B",
    textlight: "#CCEDE0",
    ctadark: "#1E492B",
    ctalight: "#CCEDE0",
    textcontrast: "#FCF6F2",
  },
  light: {
    background: "#77EBA6",
    tone: "#81FFB4",
    textdark: "#1E492B",
    textlight: "#CCEDE0",
    ctadark: "#1E492B",
    ctalight: "#CCEDE0",
    textcontrast: "#FCF6F2",
  },
};

const palette20: PaletteType = {
  slug: "20",
  name: "Cold 4",
  group: "Other",
  dark: {
    background: "#1E37CB",
    tone: "#223EE5",
    textdark: "#FBEEE7",
    textlight: "#000000",
    ctadark: "#FBEEE7",
    ctalight: "#000000",
    textcontrast: "#FCF6F2",
  },
  bright: {
    background: "#DBCFC8",
    tone: "#C2B7B1",
    textdark: "#7C6C60",
    textlight: "#FBEEE7",
    ctadark: "#7C6C60",
    ctalight: "#FBEEE7",
    textcontrast: "#FCF6F2",
  },
  light: {
    background: "#FFFFFF",
    tone: "#E5E5E5",
    textdark: "#000000",
    textlight: "#E5E5E5",
    ctadark: "#000000",
    ctalight: "#E5E5E5",
    textcontrast: "#FCF6F2",
  },
};

const palette21: PaletteType = {
  slug: "21",
  name: "Light 1",
  group: "Other",
  dark: {
    background: "#FEF8E5",
    tone: "#FCF0C9",
    textdark: "#5B3C33",
    textlight: "#FCF0C9",
    ctadark: "#5B3C33",
    ctalight: "#FCF0C9",
    textcontrast: "#FCF0C9",
  },
  bright: {
    background: "#F4EEE7",
    tone: "#E4D4C4",
    textdark: "#5B3C33",
    textlight: "#F9F6F3",
    ctadark: "#5B3C33",
    ctalight: "#F9F6F3",
    textcontrast: "#F9F6F3",
  },
  light: {
    background: "#EED8CC",
    tone: "#E7CABA",
    textdark: "#5B3C33",
    textlight: "#FBF7F5",
    ctadark: "#5B3C33",
    ctalight: "#FBF7F5",
    textcontrast: "#FBF7F5",
  },
};

const palette22: PaletteType = {
  slug: "22",
  name: "Light 2",
  group: "Other",
  dark: {
    background: "#E4F0DC",
    tone: "#F2F8ED",
    textdark: "#172112",
    textlight: "#F2F8ED",
    ctadark: "#172112",
    ctalight: "#F2F8ED",
    textcontrast: "#F2F8ED",
  },
  bright: {
    background: "#C4D7B3",
    tone: "#E3ECDB",
    textdark: "#172112",
    textlight: "#F2F7EE",
    ctadark: "#172112",
    ctalight: "#F2F7EE",
    textcontrast: "#F2F7EE",
  },
  light: {
    background: "#F2F7EE",
    tone: "#E5EED9",
    textdark: "#182211",
    textlight: "#F3F7EE",
    ctadark: "#182211",
    ctalight: "#F3F7EE",
    textcontrast: "#F3F7EE",
  },
};

const palette23: PaletteType = {
  slug: "23",
  name: "Light 3",
  group: "Other",
  dark: {
    background: "#F4EEE7",
    tone: "#E4D4C4",
    textdark: "#5B3C33",
    textlight: "#F9F6F3",
    ctadark: "#5B3C33",
    ctalight: "#F9F6F3",
    textcontrast: "#F9F6F3",
  },
  bright: {
    background: "#EED8CC",
    tone: "#E7CABA",
    textdark: "#674735",
    textlight: "#FBF7F5",
    ctadark: "#674735",
    ctalight: "#FBF7F5",
    textcontrast: "#FBF7F5",
  },
  light: {
    background: "#DCA7B4",
    tone: "#E5BCC5",
    textdark: "#391824",
    textlight: "#FBF5F6",
    ctadark: "#391824",
    ctalight: "#FBF5F6",
    textcontrast: "#FBF5F6",
  },
};

const palette24: PaletteType = {
  slug: "24",
  name: "Light 4",
  group: "Other",
  dark: {
    background: "#B1B2F9",
    tone: "#C9CBFC",
    textdark: "#271D49",
    textlight: "#EFF0FE",
    ctadark: "#271D49",
    ctalight: "#EFF0FE",
    textcontrast: "#EFF0FE",
  },
  bright: {
    background: "#C5D1FB",
    tone: "#E2E9FD",
    textdark: "#1F1D49",
    textlight: "#EFF2FE",
    ctadark: "#1F1D49",
    ctalight: "#EFF2FE",
    textcontrast: "#EFF2FE",
  },
  light: {
    background: "#EFF1FE",
    tone: "#E2E5FD",
    textdark: "#1F1D49",
    textlight: "#EFF1FE",
    ctadark: "#1F1D49",
    ctalight: "#EFF1FE",
    textcontrast: "#EFF1FE",
  },
};

const palette25: PaletteType = {
  slug: "25",
  name: "Dark 1",
  group: "Other",
  dark: {
    background: "#2F313C",
    tone: "#1C1D24",
    textdark: "#B2B9C4",
    textlight: "#17181C",
    ctadark: "#B2B9C4",
    ctalight: "#17181C",
    textcontrast: "#FCF6F2",
  },
  bright: {
    background: "#6F7C87",
    tone: "#98A9BA",
    textdark: "#17181C",
    textlight: "#B2B9C4",
    ctadark: "#17181C",
    ctalight: "#B2B9C4",
    textcontrast: "#FCF6F2",
  },
  light: {
    background: "#B2B9C4",
    tone: "#9BA1AB",
    textdark: "#2F313C",
    textlight: "#F1F1F1",
    ctadark: "#2F313C",
    ctalight: "#F1F1F1",
    textcontrast: "#FCF6F2",
  },
};

const palette26: PaletteType = {
  slug: "26",
  name: "Dark 2",
  group: "Other",
  dark: {
    background: "#070F2B",
    tone: "#142E7D",
    textdark: "#B8DDFF",
    textlight: "#142E7D",
    ctadark: "#B8DDFF",
    ctalight: "#142E7D",
    textcontrast: "#E9F6FF",
  },
  bright: {
    background: "#535C91",
    tone: "#6573AB",
    textdark: "#D3DAEA",
    textlight: "#2B2E40",
    ctadark: "#D3DAEA",
    ctalight: "#2B2E40",
    textcontrast: "#F4F6FA",
  },
  light: {
    background: "#1B1A55",
    tone: "#2A29AA",
    textdark: "#DEE8FF",
    textlight: "#292A86",
    ctadark: "#DEE8FF",
    ctalight: "#292A86",
    textcontrast: "#EDF2FF",
  },
};

const palette27: PaletteType = {
  slug: "27",
  name: "Dark 3",
  group: "Other",
  dark: {
    background: "#092635",
    tone: "#1A485F",
    textdark: "#D1F6FC",
    textlight: "#092635",
    ctadark: "#D1F6FC",
    ctalight: "#092635",
    textcontrast: "#EDFCFE",
  },
  bright: {
    background: "#5C8374",
    tone: "#425F55",
    textdark: "#DEE9E3",
    textlight: "#151E1C",
    ctadark: "#DEE9E3",
    ctalight: "#151E1C",
    textcontrast: "#F5F8F6",
  },
  light: {
    background: "#1B4242",
    tone: "#1F6665",
    textdark: "#D3F4EF",
    textlight: "#0B2628",
    ctadark: "#D3F4EF",
    ctalight: "#0B2628",
    textcontrast: "#F2FBFA",
  },
};

const palette28: PaletteType = {
  slug: "28",
  name: "Dark 5",
  group: "Other",
  dark: {
    background: "#22092C",
    tone: "#6D1D92",
    textdark: "#E8DAFF",
    textlight: "#E8DAFF",
    ctadark: "#6D1D92",
    ctalight: "#E8DAFF",
    textcontrast: "#F4F6FA",
  },
  bright: {
    background: "#F05941",
    tone: "#FDB0A4",
    textdark: "#45120A",
    textlight: "#FEF4F2",
    ctadark: "#45120A",
    ctalight: "#FEF4F2",
    textcontrast: "#FEF4F2",
  },
  light: {
    background: "#872341",
    tone: "#C73759",
    textdark: "#F8D3D9",
    textlight: "#430E1E",
    ctadark: "#F8D3D9",
    ctalight: "#430E1E",
    textcontrast: "#FDF3F4",
  },
};

const palette29: PaletteType = {
  slug: "29",
  name: "Vintage 5",
  group: "Other",
  dark: {
    background: "#56431E",
    tone: "#715B22",
    textdark: "#FFFE18",
    textlight: "#382810",
    ctadark: "#FFFE18",
    ctalight: "#382810",
    textcontrast: "#F8FAEC",
  },
  bright: {
    background: "#807C19",
    tone: "#5C5A18",
    textdark: "#FEF48A",
    textlight: "#56431E",
    ctadark: "#FEF48A",
    ctalight: "#F9F3CE",
    textcontrast: "#F9F3CE",
  },
  light: {
    background: "#282828",
    tone: "#3D3D3D",
    textdark: "#86F8FB",
    textlight: "#3D3D3D",
    ctadark: "#86F8FB",
    ctalight: "#430E1E",
    textcontrast: "#F6F6F6",
  },
};

const palette30: PaletteType = {
  slug: "30",
  name: "Vintage 6",
  group: "Other",
  dark: {
    background: "#14371F",
    tone: "#21462C",
    textdark: "#EF8A76",
    textlight: "#0D2615",
    ctadark: "#EF8A76",
    ctalight: "#0D2615",
    textcontrast: "#F3FAF4",
  },
  bright: {
    background: "#EF8A76",
    tone: "#E4684F",
    textdark: "#21462C",
    textlight: "#F8F6FC",
    ctadark: "#21462C",
    ctalight: "#F8F6FC",
    textcontrast: "#F8F6FC",
  },
  light: {
    background: "#262221",
    tone: "#463F3D",
    textdark: "#AD94D1",
    textlight: "#262221",
    ctadark: "#AD94D1",
    ctalight: "#262221",
    textcontrast: "#E6E1F3",
  },
};

const palette31: PaletteType = {
  slug: "31",
  name: "Neon 5",
  group: "Other",
  dark: {
    background: "#6DE8E9",
    tone: "#D4F9F8",
    textdark: "#242424",
    textlight: "#242424",
    ctadark: "#F9F9F9",
    ctalight: "#F9F9F9",
    textcontrast: "#F9F9F9",
  },
  bright: {
    background: "#F9F9F9",
    tone: "#DCDCDC",
    textdark: "#242424",
    textlight: "#DCDCDC",
    ctadark: "#242424",
    ctalight: "#F9F9F9",
    textcontrast: "#F9F9F9",
  },
  light: {
    background: "#292929",
    tone: "#3D3D3D",
    textdark: "#F9F9F9",
    textlight: "#292929",
    ctadark: "#F9F9F9",
    ctalight: "#292929",
    textcontrast: "#F9F9F9",
  },
};

const palette32: PaletteType = {
  slug: "32",
  name: "Cold 5",
  group: "Other",
  dark: {
    background: "#C7F07E",
    tone: "#EBFACD",
    textdark: "#1C4737",
    textlight: "#F9F9F9",
    ctadark: "#1C4737",
    ctalight: "#F9F9F9",
    textcontrast: "#F7FDE8",
  },
  bright: {
    background: "#F9F9F9",
    tone: "#EFEFEF",
    textdark: "#1C4737",
    textlight: "#F9F9F9",
    ctadark: "#1C4737",
    ctalight: "#F9F9F9",
    textcontrast: "#F9F9F9",
  },
  light: {
    background: "#1C4737",
    tone: "#3D3D3D",
    textdark: "#FDF6EE",
    textlight: "#292929",
    ctadark: "#FDF6EE",
    ctalight: "#292929",
    textcontrast: "#F9F9F9",
  },
};

const palette33: PaletteType = {
  slug: "33",
  name: "Neon 6",
  group: "Other",
  dark: {
    background: "#FAFAFA",
    tone: "#EFEFEF",
    textdark: "#000000",
    textlight: "#FAFAFA",
    ctadark: "#000000",
    ctalight: "#FAFAFA",
    textcontrast: "#FAFAFA",
  },
  bright: {
    background: "#E6FF4F",
    tone: "#fbffc7",
    textdark: "#000000",
    textlight: "#FAFAFA",
    ctadark: "#000000",
    ctalight: "#FAFAFA",
    textcontrast: "#FAFAFA",
  },
  light: {
    background: "#B6ABE1",
    tone: "#FAFAFA",
    textdark: "#000000",
    textlight: "#FAFAFA",
    ctadark: "#000000",
    ctalight: "#FAFAFA",
    textcontrast: "#FAFAFA",
  },
};

const palette34: PaletteType = {
  slug: "34",
  name: "Warm 5",
  group: "Other",
  dark: {
    background: "#EEE8DE",
    tone: "#FAF7F2",
    textdark: "#EB481F",
    textlight: "#FAFAFA",
    ctadark: "#EB481F",
    ctalight: "#FAFAFA",
    textcontrast: "#FAFAFA",
  },
  bright: {
    background: "#000000",
    tone: "#454545",
    textdark: "#FAFAFA",
    textlight: "#000000",
    ctadark: "#FAFAFA",
    ctalight: "#000000",
    textcontrast: "#FAFAFA",
  },
  light: {
    background: "#EB481F",
    tone: "#FAFAFA",
    textdark: "#000000",
    textlight: "#FAFAFA",
    ctadark: "#000000",
    ctalight: "#FAFAFA",
    textcontrast: "#FAFAFA",
  },
};

const palette35: PaletteType = {
  slug: "35",
  name: "Dark 5",
  group: "Other",
  dark: {
    background: "#212121",
    tone: "#3D3D3D",
    textdark: "#ADADAD",
    textlight: "#212121",
    ctadark: "#ADADAD",
    ctalight: "#FAFAFA",
    textcontrast: "#FAFAFA",
  },
  bright: {
    background: "#EEE8DE",
    tone: "#FAFAFA",
    textdark: "#000000",
    textlight: "#FAFAFA",
    ctadark: "#000000",
    ctalight: "#FAFAFA",
    textcontrast: "#FAFAFA",
  },
  light: {
    background: "#B9B9B9",
    tone: "#FAFAFA",
    textdark: "#000000",
    textlight: "#FAFAFA",
    ctadark: "#000000",
    ctalight: "#FAFAFA",
    textcontrast: "#FAFAFA",
  },
};

const palette36: PaletteType = {
  slug: "36",
  name: "Minimal 1",
  group: "Other",
  dark: {
    background: "#FFFFFF",
    tone: "#DCDCDC",
    textdark: "#242424",
    textlight: "#FFFFFF",
    ctadark: "#242424",
    ctalight: "#FFFFFF",
    textcontrast: "#FFFFFF",
  },
  bright: {
    background: "#FFFFFF",
    tone: "#DCDCDC",
    textdark: "#242424",
    textlight: "#FFFFFF",
    ctadark: "#242424",
    ctalight: "#FFFFFF",
    textcontrast: "#FFFFFF",
  },
  light: {
    background: "#FFFFFF",
    tone: "#DCDCDC",
    textdark: "#242424",
    textlight: "#FFFFFF",
    ctadark: "#242424",
    ctalight: "#FFFFFF",
    textcontrast: "#FFFFFF",
  },
};

const palette37: PaletteType = {
  slug: "37",
  name: "Minimal 2",
  group: "Other",
  dark: {
    background: "#292929",
    tone: "#3D3D3D",
    textdark: "#F9F9F9",
    textlight: "#292929",
    ctadark: "#F9F9F9",
    ctalight: "#292929",
    textcontrast: "#F9F9F9",
  },
  bright: {
    background: "#292929",
    tone: "#3D3D3D",
    textdark: "#F9F9F9",
    textlight: "#292929",
    ctadark: "#F9F9F9",
    ctalight: "#292929",
    textcontrast: "#F9F9F9",
  },
  light: {
    background: "#292929",
    tone: "#3D3D3D",
    textdark: "#F9F9F9",
    textlight: "#292929",
    ctadark: "#F9F9F9",
    ctalight: "#292929",
    textcontrast: "#F9F9F9",
  },
};

const palette38: PaletteType = {
  slug: "38",
  name: "Dark 6",
  group: "Other",
  dark: {
    background: "#A18161",
    tone: "##baa48a",
    textdark: "#000000",
    textlight: "#e0eaed",
    ctadark: "#000000",
    ctalight: "#000000",
    textcontrast: "#e0eaed",
  },
  bright: {
    background: "#435967",
    tone: "#527788",
    textdark: "#e0eaed",
    textlight: "#e0eaed",
    ctadark: "#000000",
    ctalight: "#000000",
    textcontrast: "#e0eaed",
  },
  light: {
    background: "#000000",
    tone: "#3D3D3D",
    textdark: "#F9F9F9",
    textlight: "#292929",
    ctadark: "#F9F9F9",
    ctalight: "#292929",
    textcontrast: "#F9F9F9",
  },
};

const palette39: PaletteType = {
  slug: "39",
  name: "Pastel 5",
  group: "Other",
  dark: {
    background: "#FFCE00",
    tone: "#ffee41",
    textdark: "#047272",
    textlight: "#F4F9F4",
    ctadark: "#047272",
    ctalight: "#ffee41",
    textcontrast: "#ffee41",
  },
  bright: {
    background: "#FCDFEB",
    tone: "#fdf2f6",
    textdark: "#047272",
    textlight: "#F3F8F8",
    ctadark: "#047272",
    ctalight: "#F3F8F8",
    textcontrast: "#F3F8F8",
  },
  light: {
    background: "#FFFFFF",
    tone: "#efefef",
    textdark: "#047272",
    textlight: "#FFFFFF",
    ctadark: "#047272",
    ctalight: "#FFFFFF",
    textcontrast: "#FFFFFF",
  },
};

const palette40: PaletteType = {
  slug: "40",
  name: "Pastel 6",
  group: "Other",
  dark: {
    background: "#C7CDFF",
    tone: "#dfe4ff",
    textdark: "#000000",
    textlight: "#FAFAFA",
    ctadark: "#000000",
    ctalight: "#dfe4ff",
    textcontrast: "#dfe4ff",
  },
  bright: {
    background: "#C7FFEE",
    tone: "#91fedf",
    textdark: "#000000",
    textlight: "#FAFAFA",
    ctadark: "#000000",
    ctalight: "#91fedf",
    textcontrast: "#91fedf",
  },
  light: {
    background: "#FFFFFF",
    tone: "#efefef",
    textdark: "#000000",
    textlight: "#FAFAFA",
    ctadark: "#000000",
    ctalight: "#FAFAFA",
    textcontrast: "#FAFAFA",
  },
};

const palette41: PaletteType = {
  slug: "41",
  name: "Vintage 7",
  group: "Other",
  dark: {
    background: "#332918",
    tone: "#56482d",
    textdark: "#ffebd5",
    textlight: "#332918",
    ctadark: "#ffebd5",
    ctalight: "#332918",
    textcontrast: "#ffebd5",
  },
  bright: {
    background: "#FFF7EF",
    tone: "#ffebd5",
    textdark: "#000000",
    textlight: "#FAFAFA",
    ctadark: "#000000",
    ctalight: "#FAFAFA",
    textcontrast: "#ffebd5",
  },
  light: {
    background: "#FFFFFF",
    tone: "#efefef",
    textdark: "#332918",
    textlight: "#FAFAFA",
    ctadark: "#332918",
    ctalight: "#FAFAFA",
    textcontrast: "#ffebd5",
  },
};

const palette42: PaletteType = {
  slug: "42",
  name: "Dark 7",
  group: "Other",
  dark: {
    background: "#C7C5AB",
    tone: "#b7b392",
    textdark: "#102523",
    textlight: "#E6E5CE",
    ctadark: "#F51B20",
    ctalight: "#102523",
    textcontrast: "#E6E5CE",
  },
  bright: {
    background: "#F51B20",
    tone: "#E6E5CE",
    textdark: "#102523",
    textlight: "#E6E5CE",
    ctadark: "#102523",
    ctalight: "#102523",
    textcontrast: "#E6E5CE",
  },
  light: {
    background: "#102523",
    tone: "#233e3a",
    textdark: "#E6E5CE",
    textlight: "#102523",
    ctadark: "#E6E5CE",
    ctalight: "#102523",
    textcontrast: "#E6E5CE",
  },
};

const palette43: PaletteType = {
  slug: "43",
  name: "Neon 7",
  group: "Other",
  dark: {
    background: "#00F402",
    tone: "#22fb20",
    textdark: "#000000",
    textlight: "#FFFFFF",
    ctadark: "#000000",
    ctalight: "#FFFFFF",
    textcontrast: "#FFFFFF",
  },
  bright: {
    background: "#FFFFFF",
    tone: "#efefef",
    textdark: "#000000",
    textlight: "#FFFFFF",
    ctadark: "#000000",
    ctalight: "#FFFFFF",
    textcontrast: "#FFFFFF",
  },
  light: {
    background: "#000000",
    tone: "#3d3d3d",
    textdark: "#FFFFFF",
    textlight: "#000000",
    ctadark: "#FFFFFF",
    ctalight: "#000000",
    textcontrast: "#FFFFFF",
  },
};

const palette44: PaletteType = {
  slug: "44",
  name: "Pastel 7",
  group: "Other",
  dark: {
    background: "#FFFAF1",
    tone: "#fff1d5",
    textdark: "#363C2E",
    textlight: "#363C2E",
    ctadark: "#C5DBFA",
    ctalight: "#FFFAF1",
    textcontrast: "#FFFAF1",
  },
  bright: {
    background: "#F0F6FF",
    tone: "#dbe9fe",
    textdark: "#363C2E",
    textlight: "#FFFFFF",
    ctadark: "#363C2E",
    ctalight: "#FFFAF1",
    textcontrast: "#FFFAF1",
  },
  light: {
    background: "#C5DBFA",
    tone: "#dde9fc",
    textdark: "#363C2E",
    textlight: "#FFFFFF",
    ctadark: "#363C2E",
    ctalight: "#FFFAF1",
    textcontrast: "#FFFAF1",
  },
};

const palette45: PaletteType = {
  slug: "45",
  name: "Minimal 3",
  group: "Other",
  dark: {
    background: "#0067B2",
    tone: "##0077cb",
    textdark: "#FFFFFF",
    textlight: "#072b4a",
    ctadark: "#FFFFFF",
    ctalight: "#072b4a",
    textcontrast: "#FFFFFF",
  },
  bright: {
    background: "#0067B2",
    tone: "##0077cb",
    textdark: "#FFFFFF",
    textlight: "#072b4a",
    ctadark: "#FFFFFF",
    ctalight: "#072b4a",
    textcontrast: "#FFFFFF",
  },
  light: {
    background: "#0067B2",
    tone: "##0077cb",
    textdark: "#FFFFFF",
    textlight: "#072b4a",
    ctadark: "#FFFFFF",
    ctalight: "#072b4a",
    textcontrast: "#FFFFFF",
  },
};

const palette46: PaletteType = {
  slug: "46",
  name: "Warm 6",
  group: "Other",
  dark: {
    background: "#0F2D32",
    tone: "#1e3f43",
    textdark: "#BDDBF6",
    textlight: "#072b4a",
    ctadark: "#BDDBF6",
    ctalight: "#072b4a",
    textcontrast: "#f1f7fd",
  },
  bright: {
    background: "#EE7F60",
    tone: "#f7bbaa",
    textdark: "#323B3A",
    textlight: "#BDDBF6",
    ctadark: "#323B3A",
    ctalight: "#072b4a",
    textcontrast: "#f1f7fd",
  },
  light: {
    background: "#1B4575",
    tone: "#1b4e89",
    textdark: "#BDDBF6",
    textlight: "#072b4a",
    ctadark: "#FFFFFF",
    ctalight: "#072b4a",
    textcontrast: "#f1f7fd",
  },
};

const palette47: PaletteType = {
  slug: "47",
  name: "Cold 6",
  group: "Other",
  dark: {
    background: "#242746",
    tone: "#7e4add",
    textdark: "#ffffff",
    textlight: "#ffffff",
    ctadark: "#7e4add",
    ctalight: "#ffffff",
    textcontrast: "#ffffff",
  },
  bright: {
    background: "#9c76e7",
    tone: "#cbbdf5",
    textdark: "#242746",
    textlight: "#ffffff",
    ctadark: "#242746",
    ctalight: "#ffffff",
    textcontrast: "#ffffff",
  },
  light: {
    background: "#a1a48f",
    tone: "#d2d2c8",
    textdark: "#242746",
    textlight: "#ffffff",
    ctadark: "#242746",
    ctalight: "#ffffff",
    textcontrast: "#ffffff",
  },
};

const palette48: PaletteType = {
  slug: "48",
  name: "Dark 8",
  group: "Other",
  dark: {
    background: "#e6d3b0",
    tone: "#1a1a1a",
    textdark: "#1a1a1a",
    textlight: "#e6d3b0",
    ctadark: "#1a1a1a",
    ctalight: "#e6d3b0",
    textcontrast: "#f5eedf",
  },
  bright: {
    background: "#3a3835",
    tone: "#58544b",
    textdark: "#e6d3b0",
    textlight: "#3a3835",
    ctadark: "#e6d3b0",
    ctalight: "#e6d3b0",
    textcontrast: "#f5eedf",
  },
  light: {
    background: "#58544b",
    tone: "#e6d3b0",
    textdark: "#e6d3b0",
    textlight: "#1a1a1a",
    ctadark: "#e6d3b0",
    ctalight: "#1a1a1a",
    textcontrast: "#f5eedf",
  },
};

const palette49: PaletteType = {
  slug: "49",
  name: "Vintage 8",
  group: "Other",
  dark: {
    background: "#fcf9ed",
    tone: "#193722",
    textdark: "#193722",
    textlight: "#fcf9ed",
    ctadark: "#193722",
    ctalight: "#fcf9ed",
    textcontrast: "#fcf9ed",
  },
  bright: {
    background: "#193722",
    tone: "#000000",
    textdark: "#fcf9ed",
    textlight: "#fcf9ed",
    ctadark: "#000000",
    ctalight: "#fcf9ed",
    textcontrast: "#fcf9ed",
  },
  light: {
    background: "#fcf9ed",
    tone: "#193722",
    textdark: "#193722",
    textlight: "#fcf9ed",
    ctadark: "#193722",
    ctalight: "#fcf9ed",
    textcontrast: "#fcf9ed",
  },
};

const palette50: PaletteType = {
  slug: "50",
  name: "Cold 7",
  group: "Other",
  dark: {
    background: "#9188e8",
    tone: "#0a0449",
    textdark: "#0a0449",
    textlight: "#ffffff",
    ctadark: "#0a0449",
    ctalight: "#ffffff",
    textcontrast: "#ffffff",
  },
  bright: {
    background: "#443f74",
    tone: "#706e87",
    textdark: "#ffffff",
    textlight: "#ffffff",
    ctadark: "#706e87",
    ctalight: "#ffffff",
    textcontrast: "#ffffff",
  },
  light: {
    background: "#e1dff9",
    tone: "#ffffff",
    textdark: "#0a0449",
    textlight: "#0a0449",
    ctadark: "#ffffff",
    ctalight: "#0a0449",
    textcontrast: "#ffffff",
  },
};



function colorSchemesWithGroupName(colorSchemes, groupName) {
  return colorSchemes.map((colorScheme) => ({
    ...colorScheme,
    group: groupName,
  }));
}

export const colorSchemes = [
  ...colorSchemesWithGroupName(
    [
      palette1, 
      palette2, 
      palette3, 
      palette4, 
      palette39, 
      palette40, 
      palette44
    ],
    "pastel",
  ),
  ...colorSchemesWithGroupName(
    [
      palette5, 
      palette6, 
      palette7, 
      palette8, 
      palette29, 
      palette30, 
      palette41,
      palette49,
    ],
    "vintage",
  ),
  ...colorSchemesWithGroupName(
    [
      palette9,
      palette10,
      palette11,
      palette12,
      palette31,
      palette33,
      palette43,
    ],
    "neon",
  ),
  ...colorSchemesWithGroupName(
    [
      palette13, 
      palette14, 
      palette15, 
      palette16, 
      palette34, 
      palette46
    ],
    "warm",
  ),
  ...colorSchemesWithGroupName(
    [
      palette17, 
      palette18, 
      palette19, 
      palette20, 
      palette32,
      palette47,
      palette50,
    ],
    "cold",
  ),
  ...colorSchemesWithGroupName(
    [
      palette21, 
      palette22, 
      palette23, 
      palette24
    ],
    "light",
  ),
  ...colorSchemesWithGroupName(
    [
      palette25,
      palette26,
      palette27,
      palette28,
      palette35,
      palette38,
      palette42,
      palette48,
    ],
    "dark",
  ),
  ...colorSchemesWithGroupName([palette36, palette37, palette45], "minimal"),
];
