import { Text, Title } from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";
import { OverlayedBackgroundImage } from "../ui/InspoOverlayedBackgroundImage";

type HeaderImageButtonContentProps = {
  title1: string;
  subtitle: string;
  button: string;
  label1: string;
  paragraph1: string;
  label2: string;
  paragraph2: string;
  label3: string;
  paragraph3: string;
  imgs: string[];
  alt: string;
  align: "center" | "bottomLeft";
};

export const HeaderImageButtonContent = {
  fashion: {
    title1: "Step into\nthe future",
    subtitle: "Dress for tomorrow.",
    button: "Discover",
    label1: "Redifining Style",
    paragraph1: "Blending tradition with sustainable design.",
    label2: "Bold Statements",
    paragraph2: "Fashion that stands out with purpose.",
    label3: "Refined Edge",
    paragraph3: "Cutting-edge designs with timeless appeal.",
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_landscape",
    }),
    alt: "Product Image",
    align: "bottomLeft",
  },
  food: {
    title1: "Taste the\nInnovation",
    subtitle: "Culinary excellence reimagined.",
    button: "Explore",
    label1: "Smart Cooking",
    paragraph1: "AI-powered recipes for perfect results.",
    label2: "Fresh Vision",
    paragraph2: "Sustainable sourcing meets modern cuisine.",
    label3: "Future Flavor",
    paragraph3: "Revolutionary techniques in gastronomy.",
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_landscape",
    }),
    alt: "Culinary Innovation Image",
    align: "bottomLeft",
  },
  beauty: {
    title1: "Beauty meets\nScience",
    subtitle: "Transform your routine.",
    button: "Learn More",
    label1: "Clean Formula",
    paragraph1: "Advanced ingredients from nature's lab.",
    label2: "Smart Beauty",
    paragraph2: "AI-powered skincare solutions.",
    label3: "Pure Results",
    paragraph3: "Clinically proven effectiveness.",
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_landscape",
    }),
    alt: "Beauty Technology Image",
    align: "bottomLeft",
  },
  editorial: {
    title1: "Stories that\nInspire",
    subtitle: "Content redefined.",
    button: "Read More",
    label1: "Climate Crisis",
    paragraph1: "Inside the race to save our planet's ecosystems.",
    label2: "Urban Evolution",
    paragraph2: "How cities are reshaping for sustainability.",
    label3: "Cultural Shift",
    paragraph3: "The changing landscape of modern society.",
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_landscape",
    }),
    alt: "Editorial Content Image",
    align: "bottomLeft",
  },
  finance: {
    title1: "Banking for\nTomorrow",
    subtitle: "Your future starts now.",
    button: "Get Started",
    label1: "Smart Money",
    paragraph1: "AI-powered\ninancial insights.",
    label2: "Secure Future",
    paragraph2: "Blockchain-secured transactions.",
    label3: "Growth Focus",
    paragraph3: "Innovative investment solutions.",
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_landscape",
    }),
    alt: "Financial Technology Image",
    align: "bottomLeft",
  },
  sport: {
    title1: "Push Your\nLimits",
    subtitle: "Performance unleashed.",
    button: "Train Now",
    label1: "Smart Training",
    paragraph1: "AI-optimized workout programs.",
    label2: "Peak Tech",
    paragraph2: "Advanced performance tracking.",
    label3: "Elite Edge",
    paragraph3: "Science-backed athletic innovation.",
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_landscape",
    }),
    alt: "Sports Technology Image",
    align: "bottomLeft",
  },
} as const satisfies Record<string, HeaderImageButtonContentProps>;

export default function HeaderHalf({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<HeaderImageButtonContentProps> & BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;
  return (
    <OverlayedBackgroundImage
      src={uploadedImagePath ?? content.imgs[imgIndex]}
      style={{
        objectFit: "cover",
        aspectRatio: "1/1",
      }}
      radius={"xxs"}
      w={"100%"}
      h={"100%"}
      {...props}
    >
      <Vertical
        className="z-10"
        fullW
        fullH
        spaceBetween
        gap={0}
        noWrap
        {...props}
      >
        <Horizontal
          px={"md"}
          py={"md"}
          fullW
          spaceBetween
          style={{ zIndex: 1 }}
        >
          <Title
            w={"60%"}
            variant="card-display-md"
            c={colorScheme.textcontrast}
            ta={"left"}
          >
            {content.title1}
          </Title>
        </Horizontal>

        <Horizontal fullW>
          <Vertical
            gap={"xs"}
            px={"md"}
            py={"md"}
            style={{ flex: 1, zIndex: 1 }}
          >
            <Text variant="card-label-md" c={colorScheme.textcontrast}>
              {content.label1}
            </Text>

            <Text
              variant="card-paragraph-sm"
              c={colorScheme.textcontrast}
              ta={"left"}
            >
              {content.paragraph1}
            </Text>
          </Vertical>
          <Vertical
            gap={"xs"}
            px={"md"}
            py={"md"}
            style={{ flex: 1, zIndex: 1 }}
          >
            <Text variant="card-label-md" c={colorScheme.textcontrast}>
              {content.label2}
            </Text>

            <Text
              variant="card-paragraph-sm"
              c={colorScheme.textcontrast}
              ta={"left"}
            >
              {content.paragraph2}
            </Text>
          </Vertical>
          <Vertical
            gap={"xs"}
            px={"md"}
            py={"md"}
            style={{ flex: 1, zIndex: 1 }}
          >
            <Text variant="card-label-md" c={colorScheme.textcontrast}>
              {content.label3}
            </Text>

            <Text
              variant="card-paragraph-sm"
              c={colorScheme.textcontrast}
              ta={"left"}
            >
              {content.paragraph3}
            </Text>
          </Vertical>
        </Horizontal>
      </Vertical>
    </OverlayedBackgroundImage>
  );
}
