export function setTitleVariables(title?: TitleConfig) {
  document.documentElement.style.setProperty(
    "--card-title-font-family",
    title?.["font-family"] ?? titleDefault["font-family"] ?? null,
  );

  (
    [
      "display-lg",
      "display-md",
      "display-sm",
      "headline-lg",
      "headline-md",
      "headline-sm",
      "headline-xs",
    ] as Array<string>
  ).forEach((order: string, i) => {
    document.documentElement.style.setProperty(
      `--card-title-font-size-${order}`,
      // @ts-ignore 7053
      title?.[order]?.["font-size"] ??
        // @ts-ignore 7053
        titleDefault[order]?.["font-size"] ??
        null,
    );
    document.documentElement.style.setProperty(
      `--card-title-line-height-${order}`,
      // @ts-ignore 7053
      title?.[order]?.["line-height"]?.toString() ??
        // @ts-ignore 7053
        titleDefault[order]?.["line-height"]?.toString() ??
        null,
    );
    document.documentElement.style.setProperty(
      `--card-title-font-weight-${order}`,
      // @ts-ignore 7053
      title?.[order]?.["font-weight"]?.toString() ??
        // @ts-ignore 7053
        titleDefault[order]?.["font-weight"]?.toString() ??
        null,
    );

    document.documentElement.style.setProperty(
      `--card-title-text-transform-${order}`,
      // @ts-ignore 7053
      title?.[order]?.["text-transform"]?.toString() ??
        // @ts-ignore 7053
        titleDefault[order]?.["text-transform"]?.toString() ??
        null,
    );
    document.documentElement.style.setProperty(
      `--card-title-letter-spacing-${order}`,
      // @ts-ignore 7053
      title?.[order]?.["letter-spacing"]?.toString() ??
        // @ts-ignore 7053
        titleDefault[order]?.["letter-spacing"]?.toString() ??
        null,
    );
  });
}

export interface TitleConfig {
  "readable-font-family"?: string;
  "font-family"?: string;
  "foundry-url"?: string;
  source?: "free" | "adobe" | "custom";
  foundry?: string;
  "display-lg"?: TitleOrderConfig;
  "display-md"?: TitleOrderConfig;
  "display-sm"?: TitleOrderConfig;
  "headline-lg"?: TitleOrderConfig;
  "headline-md"?: TitleOrderConfig;
  "headline-sm"?: TitleOrderConfig;
  "headline-xs"?: TitleOrderConfig;
}

export interface TitleOrderConfig {
  "font-size"?: string;
  "line-height"?: string;
  "font-weight"?: number;
  "text-transform"?: string;
  "letter-spacing"?: string;
}

type TitleOrdersKey = Exclude<keyof TitleConfig, "font-family">;

const titleDefault: TitleConfig = {
  "readable-font-family": undefined,
  "font-family": undefined,
  "display-lg": {
    "font-size": "72px",
    "line-height": "90%",
    "font-weight": 600,
  },
  "display-md": {
    "font-size": "52px",
    "line-height": "100%",
    "font-weight": 600,
  },
  "display-sm": {
    "font-size": "44px",
    "line-height": "100%",
    "font-weight": 600,
  },
  "headline-lg": {
    "font-size": "40px",
    "line-height": "100%",
    "font-weight": 600,
  },
  "headline-md": {
    "font-size": "36px",
    "line-height": "100%",
    "font-weight": 600,
  },
  "headline-sm": {
    "font-size": "32px",
    "line-height": "100%",
    "font-weight": 600,
  },
  "headline-xs": {
    "font-size": "24px",
    "line-height": "100%",
    "font-weight": 600,
  },
};

export const titleRubik: TitleConfig = {
  "readable-font-family": "Rubik",
  "font-family": "Rubik",
  "foundry-url": "https://fonts.google.com/specimen/Rubik",
  source: "free",
  foundry: "Google",
  "display-md": {
    "font-size": "30px",
    "line-height": "32px",
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-size": "28px",
    "line-height": "32px",
  },
};

export const titleOvo: TitleConfig = {
  "readable-font-family": "Ovo",
  "font-family": "Ovo",
  "foundry-url": "https://fonts.google.com/specimen/Ovo",
  source: "free",
  foundry: "Google",
};

export const titleArchivo: TitleConfig = {
  "readable-font-family": "Archivo",
  "font-family": "Archivo",
  "foundry-url": "https://fonts.google.com/specimen/Archivo",
  source: "free",
  foundry: "Google",
};

export const titleOswald: TitleConfig = {
  "readable-font-family": "Oswald",
  "font-family": "Oswald",
  "foundry-url": "https://fonts.google.com/specimen/Oswald",
  source: "free",
  foundry: "Google",
  "display-lg": {
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "display-md": {
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "headline-md": {
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "headline-sm": {
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "headline-xs": {
    "font-weight": 600,
    "text-transform": "uppercase",
  },
};

export const titlePhudu: TitleConfig = {
  "readable-font-family": "Phudu",
  "font-family": "Phudu",
  "foundry-url": "https://fonts.google.com/specimen/Phudu",
  source: "free",
  foundry: "Google",
  "display-lg": {
    "font-weight": 500,
    "letter-spacing": "-2px",
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-size": "30px",
    "line-height": "30px",
    "font-weight": 500,
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "font-size": "18px",
    "font-weight": 500,
    "line-height": "22px",
    "text-transform": "uppercase",
  },
  "headline-md": {
    "font-size": "16px",
    "font-weight": 500,
    "line-height": "16px",
    "text-transform": "uppercase",
  },
};

export const titleClashDisplay: TitleConfig = {
  "readable-font-family": "Clash Display",
  "font-family": "Clash Display",
  "foundry-url": "https://www.fontshare.com/?q=Clash%20Display",
  source: "free",
  foundry: "FontShare",
  "display-lg": {
    "line-height": "42px",
  },
  "display-sm": {
    "font-size": "30px",
    "line-height": "30px",
  },
};

export const titleErode: TitleConfig = {
  "readable-font-family": "Erode",
  "font-family": "Erode",
  "foundry-url": "https://www.fontshare.com/?q=erode",
  source: "free",
  foundry: "FontShare",
};

export const titleZodiak: TitleConfig = {
  "readable-font-family": "Zodiak",
  "font-family": "Zodiak",
  "foundry-url": "https://www.fontshare.com/?q=zodiak",
  source: "free",
  foundry: "FontShare",
};

export const titleGeneralSans: TitleConfig = {
  "readable-font-family": "General Sans",
  "font-family": "General Sans",
  "foundry-url": "https://www.fontshare.com/fonts/general-sans",
  source: "free",
  foundry: "FontShare",
};

export const titleTanker: TitleConfig = {
  "readable-font-family": "Tanker",
  "font-family": "Tanker",
  "foundry-url": "https://www.fontshare.com/fonts/tanker",
  source: "free",
  foundry: "FontShare",
  "display-lg": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "display-md": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "headline-md": {
    "text-transform": "uppercase",
  },
  "headline-sm": {
    "font-weight": 300,
    "text-transform": "uppercase",
  },
  "headline-xs": {
    "font-weight": 300,
    "text-transform": "uppercase",
  },
};

export const titleCabinetGrotesk: TitleConfig = {
  "readable-font-family": "Cabinet Grotesk",
  "font-family": "Cabinet Grotesk",
  "foundry-url": "https://www.fontshare.com/fonts/cabinet-grotesk",
  source: "free",
  foundry: "FontShare",
};

export const titleNewSpiritCondensed: TitleConfig = {
  "readable-font-family": "New Spirit Condensed",
  "font-family": "new-spirit-condensed",
  "foundry-url": "https://fonts.adobe.com/fonts/new-spirit/?ref=inspotype.com",
  source: "adobe",
  foundry: "Newlyn",
};

export const titleGambarino: TitleConfig = {
  "readable-font-family": "Gambarino",
  "font-family": "Gambarino",
  "foundry-url": "https://www.fontshare.com/fonts/gambarino",
  source: "free",
  foundry: "FontShare",
};

export const titleTermina: TitleConfig = {
  "readable-font-family": "Termina",
  "font-family": "termina",
  "foundry-url": "https://fonts.adobe.com/fonts/termina/?ref=inspotype.com",
  source: "adobe",
  foundry: "Fort Foundry",
  "display-lg": {
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "display-md": {
    "font-size": "36px",
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-size": "32px",
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "font-size": "24px",
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "headline-md": {
    "font-size": "20px",
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "headline-sm": {
    "font-size": "18px",
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "headline-xs": {
    "font-size": "14px",
    "font-weight": 600,
    "text-transform": "uppercase",
  },
};

export const titleMinion: TitleConfig = {
  "readable-font-family": "Minion",
  "font-family": "minion-pro",
  "foundry-url": "https://fonts.adobe.com/fonts/minion/?ref=inspotype.com",
  source: "adobe",
  foundry: "Adobe Originals",
};

export const titleFranklinGothicURW: TitleConfig = {
  "readable-font-family": "Frankling Gothic URW",
  "font-family": "franklin-gothic-ext-comp-urw",
  "foundry-url":
    "https://fonts.adobe.com/fonts/franklin-gothic-urw/?ref=inspotype.com",
  source: "adobe",
  foundry: "URW Type Foundry",
  "display-lg": {
    "font-weight": 400,
    "line-height": "100%",
    "text-transform": "uppercase",
  },
  "display-md": {
    "font-size": "62px",
    "line-height": "90%",
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-size": "54px",
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "font-size": "50px",
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "headline-md": {
    "font-size": "46px",
    "text-transform": "uppercase",
  },
  "headline-sm": {
    "font-size": "42px",
    "line-height": "90%",
    "font-weight": 300,
    "text-transform": "uppercase",
  },
  "headline-xs": {
    "font-size": "34px",
    "line-height": "90%",
    "font-weight": 300,
    "text-transform": "uppercase",
  },
};

export const titleMoret: TitleConfig = {
  "readable-font-family": "Moret",
  "font-family": "moret",
  "foundry-url": "https://fonts.adobe.com/fonts/moret/?ref=inspotype.com",
  source: "adobe",
  foundry: "The Northern Block",
  "headline-lg": {
    "font-size": "26px",
    "line-height": "24px",
  },
};

export const titleWorkSans: TitleConfig = {
  "readable-font-family": "Work Sans",
  "font-family": "Work Sans",
  "foundry-url": "https://fonts.google.com/specimen/Work+Sans",
  source: "free",
  foundry: "Google",
};

export const titleEczar: TitleConfig = {
  "readable-font-family": "Eczar",
  "font-family": "Eczar",
  "foundry-url": "https://fonts.google.com/specimen/Eczar",
  source: "free",
  foundry: "Google",
};

export const titleNewKansas: TitleConfig = {
  "readable-font-family": "New Kansas",
  "font-family": "new-kansas",
  "foundry-url": "https://fonts.adobe.com/fonts/new-kansas/?ref=inspotype.com",
  source: "adobe",
  foundry: "Newlyn",
};

export const titleParalucent: TitleConfig = {
  "readable-font-family": "Paralucent",
  "font-family": "paralucent",
  "foundry-url": "https://fonts.adobe.com/fonts/paralucent/?ref=inspotype.com",
  source: "adobe",
  foundry: "Device Fonts",
};

export const titleMarcellus: TitleConfig = {
  "readable-font-family": "Marcellus",
  "font-family": "Marcellus",
  "foundry-url": "https://fonts.google.com/specimen/Marcellus",
  source: "free",
  foundry: "Google",
};

export const titleNotoSerif: TitleConfig = {
  "readable-font-family": "Noto Serif",
  "font-family": "Noto Serif",
  "foundry-url": "https://fonts.google.com/noto/specimen/Noto+Serif",
  source: "free",
  foundry: "Google",
};

export const titleLora: TitleConfig = {
  "readable-font-family": "Lora",
  "font-family": "Lora",
  "foundry-url": "https://fonts.google.com/noto/specimen/Lora",
  source: "free",
  foundry: "Google",
};

export const titleRedaction20: TitleConfig = {
  "readable-font-family": "Redaction 20",
  "font-family": "Redaction20",
  "foundry-url": "https://mckltype.com/redaction/?ref=inspotype.com",
  source: "free",
  foundry: "Mckltype",
  "display-md": {
    "font-weight": 400,
  },
  "display-sm": {
    "font-weight": 400,
  },
  "headline-lg": {
    "font-weight": 400,
  },
  "headline-md": {
    "font-weight": 400,
  },
  "headline-sm": {
    "font-weight": 400,
  },
  "headline-xs": {
    "font-weight": 400,
  },
};

export const titleMarlideDisplay: TitleConfig = {
  "readable-font-family": "Marlide Display",
  "font-family": "marlide-display-variable",
  "foundry-url":
    "https://fonts.adobe.com/fonts/marlide-display-variable/?ref=inspotype.com",
  source: "adobe",
  foundry: "Kontour",
};

export const titlePlantin: TitleConfig = {
  "readable-font-family": "Plantin",
  "font-family": "plantin",
  "foundry-url": "https://fonts.adobe.com/fonts/plantin/?ref=inspotype.com",
  source: "adobe",
  foundry: "Monotype",
};

// custom
export const titleMangoGrotesque: TitleConfig = {
  "readable-font-family": "Mango Grotesque",
  "font-family": "MangoGrotesque",
  "foundry-url":
    "https://rajputrajesh-448.gumroad.com/l/MangoGrotesque/?ref=inspotype.com",
  source: "free",
  foundry: "Rajput Rajesh",
  "display-lg": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "display-md": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "headline-md": {
    "text-transform": "uppercase",
  },
  "headline-sm": {
    "font-weight": 300,
    "text-transform": "uppercase",
  },
  "headline-xs": {
    "font-weight": 300,
    "text-transform": "uppercase",
  },
};

export const titleBaskerville: TitleConfig = {
  "readable-font-family": "Baskerville URW",
  "font-family": "baskerville-urw",
  "foundry-url":
    "https://fonts.adobe.com/fonts/baskerville-urw/?ref=inspotype.com",
  source: "adobe",
  foundry: "URW Type Foundry",
};

export const titleStratos: TitleConfig = {
  "readable-font-family": "Stratos",
  "font-family": "stratos",
  "foundry-url": "https://fonts.adobe.com/fonts/stratos/?ref=inspotype.com",
  source: "adobe",
  foundry: "Production Type",
};

export const titleMencken: TitleConfig = {
  "readable-font-family": "Mencken Head Comp",
  "font-family": "mencken-std-head-compress",
  "foundry-url": "https://fonts.adobe.com/fonts/mencken/?ref=inspotype.com",
  source: "adobe",
  foundry: "Typofonderie",
  "display-md": {
    "font-weight": 800,
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-weight": 800,
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "font-weight": 800,
    "text-transform": "uppercase",
  },
  "headline-md": {
    "font-weight": 800,
    "text-transform": "uppercase",
  },
  "headline-sm": {
    "font-weight": 800,
    "text-transform": "uppercase",
  },
  "headline-xs": {
    "font-weight": 800,
    "text-transform": "uppercase",
  },
};

export const titleNeueHaasUnica: TitleConfig = {
  "readable-font-family": "Neue Haas Unica",
  "font-family": "neue-haas-unica",
  "foundry-url":
    "https://fonts.adobe.com/fonts/neue-haas-unica/?ref=inspotype.com",
  source: "adobe",
  foundry: "Monotype",
};

export const titleMargoBeuys: TitleConfig = {
  "readable-font-family": "MargoBeuys",
  "font-family": "MargoBeuys",
  "foundry-url":
    "https://type-department.com/collections/sans-serif-fonts/products/margobeuys/?ref=inspotype.com",
  source: "custom",
  foundry: "Type Department",
  "display-lg": {
    "font-size": "40px",
    "line-height": "40px",
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-size": "30px",
    "line-height": "30px",
  },
};

export const titleFabio: TitleConfig = {
  "readable-font-family": "Fabio XM",
  "font-family": "FabioXM",
  "foundry-url":
    "https://type-department.com/collections/sans-serif-fonts/products/fabio-xm/?ref=inspotype.com",
  source: "custom",
  foundry: "Type Department",
};

export const titlePPEiko: TitleConfig = {
  "readable-font-family": "PP Eiko",
  "font-family": "PPEiko",
  "foundry-url": "https://pangrampangram.com/products/eiko/?ref=inspotype.com",
  source: "custom",
  foundry: "Pangram Pangram",
};

export const titlePPFragmentGlare: TitleConfig = {
  "readable-font-family": "PP Fragment Glare",
  "font-family": "PPFragmentGlare",
  "foundry-url":
    "https://pangrampangram.com/products/fragment-glare/?ref=inspotype.com",
  source: "custom",
  foundry: "Pangram Pangram",
};

export const titlePPWriter: TitleConfig = {
  "readable-font-family": "PP Writer",
  "font-family": "PPWriter",
  "foundry-url":
    "https://pangrampangram.com/products/writer/?ref=inspotype.com",
  source: "custom",
  foundry: "Pangram Pangram",
};

export const titlePPCirka: TitleConfig = {
  "readable-font-family": "PP Cirka",
  "font-family": "PPCirka",
  "foundry-url": "https://pangrampangram.com/products/cirka/?ref=inspotype.com",
  source: "custom",
  foundry: "Pangram Pangram",
  "display-lg": {
    "line-height": "48px",
  },
  "display-md": {
    "font-size": "44px",
    "line-height": "46px",
  },
};

export const titlePPAgrandir: TitleConfig = {
  "readable-font-family": "PP Agrandir",
  "font-family": "PPAgrandir",
  "foundry-url":
    "https://pangrampangram.com/products/agrandir/?ref=inspotype.com",
  source: "custom",
  foundry: "Pangram Pangram",
  "display-lg": {
    "font-weight": 400,
  },
  "display-md": {
    "font-weight": 400,
  },
  "display-sm": {
    "font-weight": 400,
  },
  "headline-lg": {
    "font-weight": 400,
  },
  "headline-md": {
    "font-weight": 400,
  },
  "headline-sm": {
    "font-weight": 400,
  },
  "headline-xs": {
    "font-weight": 400,
  },
};

export const titleRotonto: TitleConfig = {
  "readable-font-family": "Rotonto",
  "font-family": "Rotonto",
  "foundry-url":
    "https://type-department.com/collections/sans-serif-fonts/products/rotonto/?ref=inspotype.com",
  source: "custom",
  foundry: "Type Department",
};

export const titleAeonikFono: TitleConfig = {
  "readable-font-family": "Aeonik Fono",
  "font-family": "AeonikFono",
  "foundry-url":
    "https://cotypefoundry.com/our-fonts/aeonik-fono/?ref=inspotype.com",
  source: "custom",
  foundry: "Cotype Foundry",
};

export const titleMessinaSerif: TitleConfig = {
  "readable-font-family": "Messina Serif",
  "font-family": "MessinaSerif",
  "foundry-url": "https://www.luzi-type.ch/messina-serif/?ref=inspotype.com",
  source: "custom",
  foundry: "Luzi Type",
};

export const titleAdobeCaslon: TitleConfig = {
  "readable-font-family": "Adobe Caslon",
  "font-family": "adobe-caslon-pro",
  "foundry-url":
    "https://fonts.adobe.com/fonts/adobe-caslon/?ref=inspotype.com",
  source: "adobe",
  foundry: "Adobe Originals",
};

export const titleRecife: TitleConfig = {
  "readable-font-family": "Recife",
  "font-family": "Recife",
  "foundry-url": "https://www.luzi-type.ch/recife/?ref=inspotype.com",
  source: "custom",
  foundry: "Luzi Type",
};

export const titleFaroBold: TitleConfig = {
  "readable-font-family": "Faro Bold",
  "font-family": "FaroBold",
  "foundry-url": "https://www.luzi-type.ch/faro/?ref=inspotype.com",
  source: "custom",
  foundry: "Luzi Type",
};

export const titleSyncopate: TitleConfig = {
  "readable-font-family": "Syncopate",
  "font-family": "Syncopate",
  "foundry-url": "https://fonts.google.com/specimen/Syncopate",
  source: "free",
  foundry: "Google",
  "display-lg": {
    "font-size": "80px",
    "font-weight": 800,
    "text-transform": "uppercase",
  },
  "display-md": {
    "font-size": "36px",
    "font-weight": 800,
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-size": "32px",
    "font-weight": 800,
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "font-size": "24px",
    "font-weight": 800,
    "text-transform": "uppercase",
  },
  "headline-md": {
    "font-size": "20px",
    "font-weight": 800,
    "text-transform": "uppercase",
  },
  "headline-sm": {
    "font-size": "18px",
    "font-weight": 800,
    "text-transform": "uppercase",
  },
  "headline-xs": {
    "font-size": "14px",
    "font-weight": 800,
    "text-transform": "uppercase",
  },
};

export const TitleVinilaComp: TitleConfig = {
  "readable-font-family": "Vinila Compressed",
  "font-family": "vinila-compressed",
  "foundry-url": "https://fonts.adobe.com/fonts/vinila/?ref=inspotype.com",
  source: "adobe",
  foundry: "Plau",
  "display-lg": {
    "font-weight": 400,
    "line-height": "52px",
  },
  "display-md": {
    "font-weight": 400,
    "line-height": "46px",
  },
  "display-sm": {
    "font-weight": 400,
    "line-height": "38px",
  },
  "headline-lg": {
    "font-weight": 400,
    "font-size": "28px",
    "line-height": "32px",
  },
};

export const TitleCalSans: TitleConfig = {
  "readable-font-family": "CalSans",
  "font-family": "CalSans",
  "foundry-url": "https://github.com/calcom/font/?ref=inspotype.com",
  source: "free",
  foundry: "Cal.com",
};

export const titleAnton: TitleConfig = {
  "readable-font-family": "Anton",
  "font-family": "Anton",
  "foundry-url": "https://fonts.google.com/specimen/Anton",
  source: "free",
  foundry: "Google",
  "display-lg": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "display-md": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "headline-md": {
    "text-transform": "uppercase",
  },
  "headline-sm": {
    "font-weight": 300,
    "text-transform": "uppercase",
  },
  "headline-xs": {
    "font-weight": 300,
    "text-transform": "uppercase",
  },
};

export const titleWhyteInktrap: TitleConfig = {
  "readable-font-family": "ABC Whyte Inktrap",
  "font-family": "ABC Whyte Inktrap",
  "foundry-url": "https://abcdinamo.com/typefaces/whyte/?ref=inspotype.com",
  source: "custom",
  foundry: "ABC Dinamo",
};

export const titleArizonaSerif: TitleConfig = {
  "readable-font-family": "ABC Arizona Serif",
  "font-family": "ABC Arizona Serif",
  "foundry-url": "https://abcdinamo.com/typefaces/arizona/?ref=inspotype.com",
  source: "custom",
  foundry: "ABC Dinamo",
};

export const titleGinto: TitleConfig = {
  "readable-font-family": "ABC Ginto",
  "font-family": "ABC Ginto",
  "foundry-url": "https://abcdinamo.com/typefaces/ginto/?ref=inspotype.com",
  source: "custom",
  foundry: "ABC Dinamo",
  "display-lg": {
    "font-size": "80px",
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "display-md": {
    "font-size": "42px",
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-size": "34px",
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "font-size": "30px",
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "headline-md": {
    "font-size": "26px",
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "headline-sm": {
    "font-size": "22px",
    "font-weight": 400,
    "text-transform": "uppercase",
  },
  "headline-xs": {
    "font-size": "20px",
    "font-weight": 400,
    "text-transform": "uppercase",
  },
};

export const titleGelica: TitleConfig = {
  "readable-font-family": "Gelica",
  "font-family": "gelica",
  "foundry-url": "https://fonts.adobe.com/fonts/gelica/?ref=inspotype.com",
  source: "adobe",
  foundry: "Dave Rowland Type",
};

export const titleSneak: TitleConfig = {
  "readable-font-family": "Sneak",
  "font-family": "Sneak",
  "foundry-url": "https://tightype.com/typefaces/sneak?ref=inspotype",
  source: "custom",
  foundry: "Tightype",
};

export const titlePlaid: TitleConfig = {
  "readable-font-family": "Plaid",
  "font-family": "Plaid",
  "foundry-url": "https://tightype.com/typefaces/plaid/?ref=inspotype.com",
  source: "custom",
  foundry: "Tightype",
  "display-lg": {
    "font-size": "80px",
    "font-weight": 700,
    "text-transform": "uppercase",
  },
  "display-md": {
    "font-size": "36px",
    "font-weight": 700,
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-size": "32px",
    "font-weight": 700,
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "font-size": "24px",
    "font-weight": 700,
    "text-transform": "uppercase",
  },
  "headline-md": {
    "font-size": "20px",
    "font-weight": 700,
    "text-transform": "uppercase",
  },
  "headline-sm": {
    "font-size": "18px",
    "font-weight": 700,
    "text-transform": "uppercase",
  },
  "headline-xs": {
    "font-size": "14px",
    "font-weight": 700,
    "text-transform": "uppercase",
  },
};

export const titleFraunces: TitleConfig = {
  "readable-font-family": "Fraunces",
  "font-family": "Fraunces",
  "foundry-url": "https://fonts.google.com/specimen/Fraunces",
  source: "free",
  foundry: "Google",
};

export const titleEpilogue: TitleConfig = {
  "readable-font-family": "Epilogue",
  "font-family": "Epilogue",
  "foundry-url": "https://fonts.google.com/specimen/Epilogue",
  source: "free",
  foundry: "Google",
};

export const titleIvyPresto: TitleConfig = {
  "readable-font-family": "Ivy Presto Headline",
  "font-family": "ivypresto-headline",
  "foundry-url":
    "https://fonts.adobe.com/fonts/ivypresto-headline/?ref=inspotype.com",
  source: "adobe",
  foundry: "Newlyn",
};

export const titlePrata: TitleConfig = {
  "readable-font-family": "Prata",
  "font-family": "Prata",
  "foundry-url": "https://fonts.google.com/specimen/Prata",
  source: "free",
  foundry: "Google",
};

export const titleRocGrotesk: TitleConfig = {
  "readable-font-family": "Roc Grotesk",
  "font-family": "roc-grotesk",
  "foundry-url": "https://fonts.adobe.com/fonts/roc-grotesk/?ref=inspotype.com",
  source: "adobe",
  foundry: "Kostic Type Foundry",
};

export const titleBricolageGrotesque: TitleConfig = {
  "readable-font-family": "Bricolage Grotesque",
  "font-family": "Bricolage Grotesque",
  "foundry-url": "https://fonts.google.com/specimen/Bricolage+Grotesque",
  source: "free",
  foundry: "Google",
  "display-lg": {
    "font-weight": 700,
  },
  "display-md": {
    "line-height": "42px",
    "font-weight": 700,
  },
  "display-sm": {
    "font-weight": 700,
  },
  "headline-lg": {
    "font-weight": 700,
  },
};

export const titleArticulat: TitleConfig = {
  "readable-font-family": "Articulat CF",
  "font-family": "articulat-cf",
  "foundry-url":
    "https://fonts.adobe.com/fonts/articulat-cf/?ref=inspotype.com",
  source: "adobe",
  foundry: "Connary Fagen",
};

export const titleArizonaFlare: TitleConfig = {
  "readable-font-family": "ABC Arizona Flare",
  "font-family": "ABC Arizona Flare",
  "foundry-url": "https://abcdinamo.com/typefaces/arizona/?ref=inspotype.com",
  source: "custom",
  foundry: "ABC Dinamo",
};

export const titleClashGrotesk: TitleConfig = {
  "readable-font-family": "Clash Grotesk",
  "font-family": "Clash Grotesk",
  "foundry-url": "https://www.fontshare.com/fonts/clash-grotesk",
  source: "free",
  foundry: "FontShare",
  "display-lg": {
    "font-weight": 500,
  },
  "display-md": {
    "font-weight": 500,
  },
  "display-sm": {
    "font-weight": 500,
  },
  "headline-lg": {
    "font-weight": 600,
  },
  "headline-md": {
    "font-weight": 600,
  },
  "headline-sm": {
    "font-weight": 600,
  },
  "headline-xs": {
    "font-weight": 600,
  },
};

export const titleInstrumentSerif: TitleConfig = {
  "readable-font-family": "Instrument Serif",
  "font-family": "Instrument Serif",
  "foundry-url": "https://fonts.google.com/specimen/Instrument+Serif",
  source: "free",
  foundry: "Google",
};

export const titleEBGaramond: TitleConfig = {
  "readable-font-family": "EB Garamond",
  "font-family": "EB Garamond",
  "foundry-url": "https://fonts.google.com/specimen/EB+Garamond",
  source: "free",
  foundry: "Google",
};

export const titleAktivGroteskExt: TitleConfig = {
  "readable-font-family": "Aktiv Grotesk Ext",
  "font-family": "aktiv-grotesk-extended",
  "foundry-url":
    "https://fonts.adobe.com/fonts/aktiv-grotesk-extended/?ref=inspotype.com",
  source: "adobe",
  foundry: "Dalton Maag",
  "display-lg": {
    "font-size": "58px",
  },
};

export const titleBarlowCond: TitleConfig = {
  "readable-font-family": "Barlow Condensed",
  "font-family": "Barlow Condensed",
  "foundry-url": "https://fonts.google.com/specimen/Barlow+Condensed",
  source: "free",
  foundry: "Google",
  "display-lg": {
    "text-transform": "uppercase",
  },
  "display-md": {
    "text-transform": "uppercase",
  },
  "display-sm": {
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "text-transform": "uppercase",
  },
  "headline-md": {
    "text-transform": "uppercase",
  },
  "headline-sm": {
    "text-transform": "uppercase",
  },
  "headline-xs": {
    "text-transform": "uppercase",
  },
};

export const titleBaiJamjuree: TitleConfig = {
  "readable-font-family": "Bai Jamjuree",
  "font-family": "Bai Jamjuree",
  "foundry-url": "https://fonts.google.com/specimen/Bai+Jamjuree",
  source: "free",
  foundry: "Google",
  "display-lg": {
    "font-size": "62px",
  },
};

export const titleNeueHaasGrotesk: TitleConfig = {
  "readable-font-family": "Neue Haas Grotesk",
  "font-family": "neue-haas-grotesk-text",
  "foundry-url":
    "https://fonts.adobe.com/fonts/neue-haas-grotesk/?ref=inspotype.com",
  source: "adobe",
  foundry: "Monotype",
  "display-lg": {
    "font-size": "62px",
    "line-height": "90%",
  },
};

export const titleLoosNormal: TitleConfig = {
  "readable-font-family": "Loos Normal",
  "font-family": "loos-normal",
  "foundry-url": "https://fonts.adobe.com/fonts/loos-normal/?ref=inspotype.com",
  source: "adobe",
  foundry: "CSTM Fonts",
};

export const titleRomCompressed: TitleConfig = {
  "readable-font-family": "ABC ROM Comp",
  "font-family": "ABC ROM Compressed",
  "foundry-url": "https://abcdinamo.com/typefaces/rom/?ref=inspotype.com",
  source: "custom",
  foundry: "ABC Dinamo",
  "display-lg": {
    "text-transform": "uppercase",
  },
  "display-md": {
    "text-transform": "uppercase",
  },
  "display-sm": {
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "text-transform": "uppercase",
  },
  "headline-md": {
    "text-transform": "uppercase",
  },
  "headline-sm": {
    "text-transform": "uppercase",
  },
  "headline-xs": {
    "text-transform": "uppercase",
  },
};

export const titlePlusJakartaSans: TitleConfig = {
  "readable-font-family": "Plus Jakarta Sans",
  "font-family": "Plus Jakarta Sans",
  "foundry-url": "https://fonts.google.com/specimen/Plus+Jakarta+Sans",
  source: "free",
  foundry: "Google",
};

export const titleKarla: TitleConfig = {
  "readable-font-family": "Karla",
  "font-family": "Karla",
  "foundry-url": "https://fonts.google.com/specimen/Karla",
  source: "free",
  foundry: "Google",
};

export const titlePPEikoItalic: TitleConfig = {
  "readable-font-family": "PP Eiko Italic",
  "font-family": "PPEikoItalic",
  "foundry-url": "https://pangrampangram.com/products/eiko/?ref=inspotype.com",
  source: "custom",
  foundry: "Pangram Pangram",
  "display-lg": {
    "font-weight": 400,
  },
  "display-md": {
    "font-weight": 400,
  },
  "display-sm": {
    "font-weight": 400,
  },
  "headline-lg": {
    "font-weight": 400,
  },
  "headline-md": {
    "font-weight": 400,
  },
  "headline-sm": {
    "font-weight": 400,
  },
  "headline-xs": {
    "font-weight": 400,
  },
};

// export const titleSourceSerif: TitleConfig = {
//   "readable-font-family": "Source Serif 4",
//   "font-family": "Source Serif 4",
//   "foundry-url": "https://fonts.google.com/specimen/Source+Serif+4",
//   source: "free",
//   foundry: "Google",
// };

export const titleAeonik: TitleConfig = {
  "readable-font-family": "Aeonik",
  "font-family": "Aeonik",
  "foundry-url":
    "https://cotypefoundry.com/our-fonts/aeonik/?ref=inspotype.com",
  source: "custom",
  foundry: "Cotype Foundry",
};

export const titlePlayfairDisplay: TitleConfig = {
  "readable-font-family": "Playfair Display",
  "font-family": "Playfair Display",
  "foundry-url": "https://fonts.google.com/specimen/Playfair+Display",
  source: "free",
  foundry: "Google",
};

export const titlePPMori: TitleConfig = {
  "readable-font-family": "PP Mori",
  "font-family": "PPMori",
  "foundry-url": "https://pangrampangram.com/products/mori/?ref=inspotype.com",
  source: "custom",
  foundry: "Pangram Pangram",
};

export const titleLibreBaskerville: TitleConfig = {
  "readable-font-family": "Libre Baskerville",
  "font-family": "Libre Baskerville",
  "foundry-url": "https://fonts.google.com/specimen/Libre+Franklin",
  source: "free",
  foundry: "Google",
  "display-lg": {
    "font-weight": 400,
  },
  "display-md": {
    "font-weight": 400,
  },
  "display-sm": {
    "font-weight": 400,
  },
  "headline-lg": {
    "font-weight": 400,
  },
  "headline-md": {
    "font-weight": 400,
  },
  "headline-sm": {
    "font-weight": 400,
  },
  "headline-xs": {
    "font-weight": 400,
  },
};

export const titleIvyPrestoDisplay: TitleConfig = {
  "readable-font-family": "Ivy Presto Display",
  "font-family": "ivypresto-display",
  "foundry-url":
    "https://fonts.adobe.com/fonts/ivypresto-display/?ref=inspotype.com",
  source: "adobe",
  foundry: "Newlyn",
  "display-lg": {
    "font-weight": 400,
  },
  "display-md": {
    "font-weight": 400,
  },
  "display-sm": {
    "font-weight": 400,
  },
  "headline-lg": {
    "font-weight": 400,
  },
  "headline-md": {
    "font-weight": 400,
  },
  "headline-sm": {
    "font-weight": 400,
  },
  "headline-xs": {
    "font-weight": 400,
  },
};

export const titlePoleno: TitleConfig = {
  "readable-font-family": "Poleno",
  "font-family": "poleno",
  "foundry-url": "https://fonts.adobe.com/fonts/poleno/?ref=inspotype.com",
  source: "adobe",
  foundry: "DizajnDesign",
  "display-lg": {
    "line-height": "100%",
  },
  "display-md": {
    "line-height": "90%",
  },
  "display-sm": {
    "line-height": "90%",
  },
  "headline-lg": {
    "line-height": "90%",
  },
  "headline-md": {
    "line-height": "90%",
  },
  "headline-sm": {
    "line-height": "90%",
  },
  "headline-xs": {
    "line-height": "90%",
  },
};

export const titlePanchang: TitleConfig = {
  "readable-font-family": "Panchang",
  "font-family": "Panchang",
  "foundry-url": "https://www.fontshare.com/fonts/panchang/?ref=inspotype.com",
  source: "free",
  foundry: "FontShare",
  "display-lg": {
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "display-md": {
    "font-size": "36px",
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "display-sm": {
    "font-size": "32px",
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "headline-lg": {
    "font-size": "24px",
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "headline-md": {
    "font-size": "20px",
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "headline-sm": {
    "font-size": "18px",
    "font-weight": 600,
    "text-transform": "uppercase",
  },
  "headline-xs": {
    "font-size": "14px",
    "font-weight": 600,
    "text-transform": "uppercase",
  },
};

export const titleBookmania: TitleConfig = {
    "readable-font-family": "Bookmania",
    "font-family": "bookmania",
    "foundry-url":
      "https://fonts.adobe.com/fonts/bookmania/?ref=inspotype.com",
    source: "adobe",
    foundry: "Mark Simonson Studio",
  };

  export const titleBricolageGrotesqueCond: TitleConfig = {
    "readable-font-family": "Bricolage Grotesque Cond",
    "font-family": "BricolageGrotesqueCond",
    "foundry-url": "https://ateliertriay.github.io/bricolage/#download",
    source: "free",
    foundry: "Google",
    "display-lg": {
      "font-weight": 700,
    },
    "display-md": {
      "line-height": "42px",
      "font-weight": 700,
    },
    "display-sm": {
      "font-weight": 700,
    },
    "headline-lg": {
      "font-weight": 700,
    },
  };

  export const titleGosh: TitleConfig = {
    "readable-font-family": "Gosh",
    "font-family": "Gosh",
    "foundry-url":
      "https://www.wearegradient.net/typefaces/gosh/?ref=inspotype.com",
    source: "custom",
    foundry: "Gradient",
    "display-lg": {
      "font-weight": 600,
      "text-transform": "uppercase",
    },
    "display-md": {
      "font-size": "48px",
      "font-weight": 600,
      "text-transform": "uppercase",
    },
    "display-sm": {
      "font-size": "36px",
      "font-weight": 600,
      "text-transform": "uppercase",
    },
    "headline-lg": {
      "font-size": "30px",
      "font-weight": 600,
      "text-transform": "uppercase",
    },
    "headline-md": {
      "font-size": "24px",
      "font-weight": 600,
      "text-transform": "uppercase",
    },
    "headline-sm": {
      "font-size": "20px",
      "font-weight": 600,
      "text-transform": "uppercase",
    },
    "headline-xs": {
      "font-size": "18px",
      "font-weight": 600,
      "text-transform": "uppercase",
    },
  };

  export const titleOkaySerif: TitleConfig = {
    "readable-font-family": "OkaySerif",
    "font-family": "OkaySerif",
    "foundry-url": "https://www.wearegradient.net/typefaces/okay-serif/?ref=inspotype.com",
    source: "custom",
    foundry: "Gradient",
  };

  export const titleKokoro: TitleConfig = {
    "readable-font-family": "Kokoro",
    "font-family": "Kokoro",
    "foundry-url": "https://www.wearegradient.net/typefaces/kokoro/?ref=inspotype.com",
    source: "custom",
    foundry: "Gradient",
  };

  export const titleUncutSans: TitleConfig = {
    "readable-font-family": "Uncut Sans",
    "font-family": "UncutSans",
    "foundry-url": "https://uncut.wtf/sans-serif/uncut-sans/?ref=inspotype.com",
    source: "free",
    foundry: "Uncut.wtf",
  };

  export const titleFraise: TitleConfig = {
    "readable-font-family": "Fraise",
    "font-family": "Fraise",
    "foundry-url": "https://www.daltonmaag.com/font-library/fraise.html?ref=inspotype.com",
    source: "custom",
    foundry: "Dalton Maag",
  };

  export const titlePerenelleSans: TitleConfig = {
    "readable-font-family": "Perenelle Sans",
    "font-family": "PerenelleSans",
    "foundry-url": "https://www.daltonmaag.com/font-library/perenelle-sans.html?ref=inspotype.com",
    source: "custom",
    foundry: "Dalton Maag",
  };

  export const titlePerenelleSerif: TitleConfig = {
    "readable-font-family": "Perenelle Serif",
    "font-family": "PerenelleSerif",
    "foundry-url": "https://www.daltonmaag.com/font-library/perenelle-serif.html?ref=inspotype.com",
    source: "custom",
    foundry: "Dalton Maag",
  };

  export const titleHighgateChisel: TitleConfig = {
    "readable-font-family": "Highgate Chisel",
    "font-family": "Highgate",
    "foundry-url": "https://www.daltonmaag.com/font-library/highgate.html?ref=inspotype.com",
    source: "custom",
    foundry: "Dalton Maag",
  };

  export const titleNoka: TitleConfig = {
    "readable-font-family": "Noka",
    "font-family": "noka",
    "foundry-url": "https://fonts.adobe.com/fonts/noka/?ref=inspotype.com",
    source: "adobe",
    foundry: "Blackletra",
    "display-lg": {
      "font-weight": 600,
      "text-transform": "uppercase",
    },
    "display-md": {
      "font-size": "46px",
      "font-weight": 600,
      "text-transform": "uppercase",
    },
    "display-sm": {
      "font-size": "42px",
      "font-weight": 600,
      "text-transform": "uppercase",
    },
    "headline-lg": {
      "font-size": "34px",
      "font-weight": 600,
      "text-transform": "uppercase",
    },
    "headline-md": {
      "font-size": "30px",
      "font-weight": 600,
      "text-transform": "uppercase",
    },
    "headline-sm": {
      "font-size": "28px",
      "font-weight": 600,
      "text-transform": "uppercase",
    },
    "headline-xs": {
      "font-size": "24px",
      "font-weight": 600,
      "text-transform": "uppercase",
    },
  };

  export const titleJubel: TitleConfig = {
    "readable-font-family": "Jubel",
    "font-family": "Jubel",
    "foundry-url":
      "https://typeoffeeling.com/products/jubel/?ref=inspotype.com",
    source: "custom",
    foundry: "Type of Feeling",
    "display-lg": {
      "font-weight": 400,
    },
    "display-md": {
      "font-weight": 400,
    },
    "display-sm": {
      "font-weight": 400,
    },
    "headline-lg": {
      "font-weight": 400,
    },
    "headline-md": {
      "font-weight": 400,
    },
    "headline-sm": {
      "font-weight": 400,
    },
    "headline-xs": {
      "font-weight": 400,
    },
  };

  export const titleElysian: TitleConfig = {
    "readable-font-family": "Elysian",
    "font-family": "Elysian",
    "foundry-url": "https://typeoffeeling.com/products/elysian/?ref=inspotype.com",
    source: "custom",
    foundry: "Type of Feeling",
  };

  export const titleOnsra: TitleConfig = {
    "readable-font-family": "Onsra",
    "font-family": "Onsra",
    "foundry-url":
      "https://typeoffeeling.com/products/onsra/?ref=inspotype.com",
    source: "custom",
    foundry: "Type of Feeling",
    "display-lg": {
      "font-weight": 400,
    },
    "display-md": {
      "font-size": "40px",
      "font-weight": 400,
    },
    "display-sm": {
      "font-size": "38px",
      "font-weight": 400,
    },
    "headline-lg": {
      "font-size": "30px",
      "font-weight": 400,
    },
    "headline-md": {
      "font-size": "28px",
      "font-weight": 400,
    },
    "headline-sm": {
      "font-size": "20px",
      "font-weight": 400,
    },
    "headline-xs": {
      "font-size": "24px",
      "font-weight": 400,
    },
  };

  export const titleSsonder: TitleConfig = {
    "readable-font-family": "Ssonder",
    "font-family": "Ssonder",
    "foundry-url": "https://typeoffeeling.com/products/ssonder/?ref=inspotype.com",
    source: "custom",
    foundry: "Type of Feeling",
  };

  export const titleALTKlarinet: TitleConfig = {
    "readable-font-family": "ALT Klarinet",
    "font-family": "ALTKlarinet",
    "foundry-url": "https://alt-tf.com/products/alt-klarinet/?ref=inspotype.com",
    source: "custom",
    foundry: "ALT.tf",
  };

  export const titleALTMariaClara: TitleConfig = {
    "readable-font-family": "ALT Maria Clara",
    "font-family": "ALTMariaClara",
    "foundry-url": "https://alt-tf.com/products/test-alt-riviera/?ref=inspotype.com",
    source: "custom",
    foundry: "ALT.tf",
  };
  
  export const titleALTRigatoni: TitleConfig = {
    "readable-font-family": "ALT Rigatoni",
    "font-family": "ALTRigatoni",
    "foundry-url":
      "https://alt-tf.com/products/alt-rigatoni/?ref=inspotype.com",
    source: "custom",
    foundry: "ALT.tf",
    "display-lg": {
      "font-weight": 400,
      "line-height": "120%",
    },
    "display-md": {
      "font-size": "36px",
      "font-weight": 400,
      "line-height": "120%",
    },
    "display-sm": {
      "font-size": "32px",
      "font-weight": 400,
      "line-height": "130%",
    },
    "headline-lg": {
      "font-size": "30px",
      "font-weight": 400,
      "line-height": "130%",
    },
    "headline-md": {
      "font-size": "22px",
      "font-weight": 400,
      "line-height": "130%",
    },
    "headline-sm": {
      "font-size": "18px",
      "font-weight": 400,
      "line-height": "130%",
    },
    "headline-xs": {
      "font-size": "14px",
      "font-weight": 400,
      "line-height": "130%",
    },
  };

  export const titleMullet: TitleConfig = {
    "readable-font-family": "Mulett",
    "font-family": "Mulett",
    "foundry-url": "https://www.giuliaboggio.xyz/fonts/mulett/?ref=inspotype.com",
    source: "custom",
    foundry: "Giulia Boggio",
  };

  export const titleRevetra: TitleConfig = {
    "readable-font-family": "Revetra",
    "font-family": "Revetra",
    "foundry-url": "https://www.giuliaboggio.xyz/fonts/revetra/?ref=inspotype.com",
    source: "custom",
    foundry: "Giulia Boggio",
  };

  export const titleCormorant: TitleConfig = {
    "readable-font-family": "Cormorant",
    "font-family": "Cormorant",
    "foundry-url": "https://fonts.google.com/specimen/Cormorant",
    source: "free",
    foundry: "Google",
    "display-lg": {
      "font-weight": 700,
    },
  
    "display-md": {
      "font-weight": 700,
    },
  
    "display-sm": {
      "font-weight": 700,
    },
  
    "headline-lg": {
      "font-weight": 700,
    },
  
    "headline-md": {
      "font-weight": 700,
    },
  
    "headline-sm": {
      "font-weight": 700,
    },
  
    "headline-xs": {
      "font-weight": 700,
 
    },
  };

  export const titleFutura: TitleConfig = {
    "readable-font-family": "Futura PT",
    "font-family": "futura-pt",
    "foundry-url": "https://fonts.adobe.com/fonts/futura-pt/?ref=inspotype.com",
    source: "adobe",
    foundry: "Paratype",
    "display-lg": {
      "font-weight": 600,
      "text-transform": "uppercase",
    },
  
    "display-md": {
      "font-weight": 600,
      "text-transform": "uppercase",
    },
  
    "display-sm": {
      "font-weight": 600,
      "text-transform": "uppercase",
    },
  
    "headline-lg": {
      "font-weight": 600,
      "text-transform": "uppercase",
    },
  
    "headline-md": {
      "font-weight": 600,
      "text-transform": "uppercase",
    },
  
    "headline-sm": {
      "font-weight": 600,
      "text-transform": "uppercase",
    },
  
    "headline-xs": {
      "font-weight": 600,
      "text-transform": "uppercase",
    },
  };

  export const titleBebasNeue: TitleConfig = {
    "readable-font-family": "Bebas Neue",
    "font-family": "Bebas Neue",
    "foundry-url": "https://fonts.google.com/specimen/Bebas+Neue",
    source: "free",
    foundry: "Google",
  };

  export const titleProximaSera: TitleConfig = {
    "readable-font-family": "Proxima Sera",
    "font-family": "proxima-sera",
    "foundry-url": "https://fonts.adobe.com/fonts/proxima-sera/?ref=inspotype.com",
    source: "adobe",
    foundry: "Mark Simonson Studio",
  };

  export const titleModeratExtended: TitleConfig = {
    "readable-font-family": "Moderat Extended",
    "font-family": "ModeratExtended",
    "foundry-url": "https://tightype.com/typefaces/moderat/?ref=inspotype.com",
    source: "custom",
    foundry: "Tightype",
  };

  export const titleDMSans: TitleConfig = {
    "readable-font-family": "DM Sans",
    "font-family": "DM Sans",
    "foundry-url": "https://fonts.google.com/specimen/DM+Sans",
    source: "free",
    foundry: "Google",
  };

  export const titleDesirePro: TitleConfig = {
    "readable-font-family": "Desire Pro",
    "font-family": "desire-pro",
    "foundry-url": "https://fonts.adobe.com/fonts/desire-pro/?ref=inspotype.com",
    source: "adobe",
    foundry: "Borges Lettering & Design",
    "display-lg": {
      "font-weight": 400,
      "text-transform": "uppercase",
    },
  
    "display-md": {
      "font-weight": 400,
      "text-transform": "uppercase",
    },
  
    "display-sm": {
      "font-weight": 400,
      "text-transform": "uppercase",
    },
  
    "headline-lg": {
      "font-weight": 400,
      "text-transform": "uppercase",
    },
  
    "headline-md": {
      "font-weight": 400,
      "text-transform": "uppercase",
    },
  
    "headline-sm": {
      "font-weight": 400,
      "text-transform": "uppercase",
    },
  
    "headline-xs": {
      "font-weight": 400,
      "text-transform": "uppercase",
    },
  };

  export const titleAktivGrotesk: TitleConfig = {
    "readable-font-family": "Aktiv Grotesk",
    "font-family": "aktiv-grotesk",
    "foundry-url":
      "https://fonts.adobe.com/fonts/aktiv-grotesk/?ref=inspotype.com",
    source: "adobe",
    foundry: "Dalton Maag",
  };

  export const titleModerat: TitleConfig = {
    "readable-font-family": "Moderat",
    "font-family": "Moderat",
    "foundry-url": "https://tightype.com/typefaces/moderat/?ref=inspotype.com",
    source: "custom",
    foundry: "Tightype",
  };

  export const titleGeist: TitleConfig = {
    "readable-font-family": "Geist",
    "font-family": "Geist",
    "foundry-url": "https://fonts.google.com/specimen/Geist",
    source: "free",
    foundry: "Google Fonts",
  };

