import { Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications, showNotification } from "@mantine/notifications";
import {
  IconArrowsShuffle,
  IconCheck,
  IconLock,
  IconPhotoDown,
  IconPhotoUp,
  IconReplace,
} from "@tabler/icons-react";
import html2canvas from "html2canvas";
import { useCallback, useEffect, useState } from "react";
import { useFolders } from "../../contexts/FoldersContext";
import { useFonts } from "../../contexts/FontsContext";
import { useIndustry } from "../../contexts/IndustryContext";
import { useUser } from "../../contexts/UserContext";
import { useIsMobile } from "../../hooks/useIsMobile";
import { InspoEvent, ModalSource } from "../../utils";
import { Vertical } from "../mantine-layout-components/Vertical";
import { openModalLifetimeIfNeeded } from "../ModalLifetime";
import { openModalLoginIfNeeded } from "../ModalLogin";
import { InspoKeyboardShortcutTooltip } from "./InspoKeyboardShortcutTooltip";
import { InspoToolbarActionIcon } from "./InspoToolbarActionIcon";

type OverlayButtonConditions = {
  downloadImageOfCard: boolean;
  shuffleCard: boolean;
  shuffleImages: boolean;
  lockImage: boolean;
  uploadImage: boolean;
};

export function InspoCardOverlay({
  show,
  forceShowUploadImage,
  imgs,
  currentImageIndex,
  imgLocked,
  setImgLocked,
  setCurrentImgIndex,
  uploadedImagePath,
  setUploadedImagePath,
  getFullImageUrl,
  onChangeCard,
}: {
  show: boolean;
  forceShowUploadImage?: boolean;
  imgs: string[];
  currentImageIndex: number;
  imgLocked?: boolean;
  setImgLocked: (value: boolean) => void;
  setCurrentImgIndex: (value: number) => void;
  uploadedImagePath: string | null;
  setUploadedImagePath: (path: string | null) => void;
  getFullImageUrl: (path: string | null) => string | null;
  onChangeCard: (() => void) | null;
}) {
  const { user, isPro } = useUser();
  const { folders } = useFolders();
  const isMobile = useIsMobile();
  const [isUploading, setIsUploading] = useState(false);

  const { title, body } = useFonts();
  const { industry } = useIndustry();

  const onChangeImage = useCallback(() => {
    if (imgLocked) return;
    const newIndex = Math.floor(Math.random() * imgs.length);
    setCurrentImgIndex(newIndex);
    document.dispatchEvent(new CustomEvent(InspoEvent.SET_HISTORY_CHECKPOINT));
  }, [imgLocked, imgs.length, setCurrentImgIndex]);

  const onLockImage = useCallback(() => {
    // Check if the uploadedImagePath is present in any saved moodboard
    const isImageInSavedMoodboard =
      uploadedImagePath &&
      folders.some((folder) =>
        folder.moodboards.some((moodboard) => {
          const decodedMoodboard = decodeURIComponent(moodboard);
          return decodedMoodboard.includes(uploadedImagePath);
        }),
      );

    if (uploadedImagePath && !isImageInSavedMoodboard) {
      modals.openConfirmModal({
        title: (
          <Text variant="paragraph-xl">Unlock and delete uploaded image?</Text>
        ),
        children: (
          <>
            <Text variant="paragraph-lg">
              Unlocking this image will delete it from your uploads. This action
              cannot be undone. © <br />
              <br />
              You can keep this image by saving this moodboard in a folder.
              <br />
              <br />
              Are you sure you want to continue?
            </Text>
          </>
        ),
        labels: {
          confirm: "Yes, unlock and delete",
          cancel: "No, keep locked",
        },
        onConfirm: async () => {
          try {
            const response = await fetch("/api/user_uploads", {
              method: "DELETE",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ filePath: uploadedImagePath }),
            });

            if (response.ok) {
              setUploadedImagePath(null);
              showNotification({
                title: "Image Unlocked",
                message: "The uploaded image has been deleted.",
              });
            } else {
              throw new Error("Failed to delete the image");
            }
          } catch (error) {
            console.error("Error deleting file:", error);
            showNotification({
              title: "Error",
              message: "Failed to delete the uploaded image.",
            });
          }
        },
      });
    } else {
      setImgLocked(!imgLocked);
    }
  }, [imgLocked, setImgLocked, uploadedImagePath, setUploadedImagePath]);

  const isImageUsedInOtherMoodboards = useCallback(
    (imagePath: string) => {
      return folders.some((folder) =>
        folder.moodboards.some((moodboard) => {
          const decodedMoodboard = decodeURIComponent(moodboard);
          return decodedMoodboard.includes(imagePath);
        }),
      );
    },
    [folders],
  );

  const deleteImage = useCallback(async (imagePath: string) => {
    try {
      const response = await fetch("/api/user_uploads", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ filePath: imagePath }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete the image");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      throw error;
    }
  }, []);

  const handleFileUpload = async (file: File) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("/api/user_uploads", {
        method: "POST",
        body: formData,
      });

      const result = await response.json();

      if (response.ok) {
        const newImagePath = result.path;
        const isReplacing =
          uploadedImagePath !== null &&
          !isImageUsedInOtherMoodboards(uploadedImagePath!);

        setUploadedImagePath(newImagePath);
        showNotification({
          title: "Upload Successful",
          message: isReplacing
            ? "Your image has been uploaded and replaced the previous one."
            : "Your image has been uploaded successfully.",
        });

        if (isReplacing) {
          await deleteImage(uploadedImagePath!);
        }
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      showNotification({
        title: "Upload Failed",
        message: error.message || "There was an error uploading your file.",
      });
    } finally {
      setIsUploading(false);
    }
  };

  const onUploadImage = useCallback(() => {
    if (
      openModalLoginIfNeeded({
        user: user,
        isMobile: false,
        source: "upload_image",
      })
    )
      return;
    if (
      openModalLifetimeIfNeeded({
        user: user,
        isPro,
        source: ModalSource.UPLOAD_IMAGE,
      })
    )
      return;

    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/png, image/jpeg, image/webp";
    fileInput.onchange = (e: Event) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        handleFileUpload(file);
      }
    };
    fileInput.click();
  }, [isPro]);

  useEffect(() => {
    function onEvent() {
      onChangeImage();
    }

    document.addEventListener(InspoEvent.RANDOM, onEvent);

    return () => {
      document.removeEventListener(InspoEvent.RANDOM, onEvent);
    };
  }, [onChangeImage]);

  function handleOnChangeCard() {
    if (
      openModalLoginIfNeeded({
        user: user,
        isMobile: false,
        source: "upload_image",
      })
    ) {
      return;
    }
    if (
      openModalLifetimeIfNeeded({
        user: user,
        isPro,
        source: ModalSource.CHANGE_CARD,
      })
    ) {
      return;
    }

    onChangeCard && onChangeCard();
  }

  const onDownloadImageOfCard = useCallback(async () => {
    if (
      openModalLoginIfNeeded({
        user,
        isMobile: false,
        source: "save_card_img",
      })
    ) {
      return;
    }

    if (
      openModalLifetimeIfNeeded({
        user,
        isPro,
        source: ModalSource.SAVE_MOODBOARD_IMG,
      })
    ) {
      return;
    }

    const cardElement =
      document.getElementById("InspoCardOverlay")?.parentElement;
    if (!cardElement) return;

    // Create a clone of the cardElement without the overlay
    const overlay = cardElement.querySelector("#InspoCardOverlay");

    if (overlay) {
      try {
        // @ts-ignore
        overlay.style.opacity = "0";
      } catch (error) {
        console.error("Error removing overlay:", error);
      }
    }

    const canvas = await html2canvas(cardElement as HTMLElement, {
      scale: 4,
      useCORS: true,
      backgroundColor: null,
    });

    const filename = `inspotype-card-industry-${industry["label"]}-title-${title.font["readable-font-family"]}-body-${body.font["readable-font-family"]}.png`;

    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = filename;
    link.click();

    notifications.show({
      title: "Downloaded moodboard image",
      message: "Check your downloads folder",
      color: "black",
      icon: <IconCheck size={14} />,
    });
  }, [uploadedImagePath, getFullImageUrl]);

  const overlayButtonConditions: OverlayButtonConditions = {
    downloadImageOfCard: true,
    shuffleCard: !!onChangeCard,
    shuffleImages: !forceShowUploadImage && imgs.length > 1,
    lockImage:
      imgs.length > 1 || ((forceShowUploadImage ?? false) && !!imgLocked),
    uploadImage: imgs.length > 1 || (forceShowUploadImage ?? false),
  };

  if (isMobile) return null;
  if (!show) return null;
  if (!Object.values(overlayButtonConditions).some(Boolean)) {
    return null;
  }

  return (
    <Vertical
      id="InspoCardOverlay"
      className="InspoCardOverlay"
      pos={"absolute"}
      top={0}
      left={0}
      fullW
      style={{ zIndex: 1, zoom: 0.7 }}
    >
      <Vertical
        pos={"absolute"}
        right={8}
        top={8}
        gap={"xxs"}
        bg="white"
        p={"xxs"}
        style={{
          borderRadius: "var(--mantine-radius-xs)",
          pointerEvents: "all",
        }}
      >
        <InspoKeyboardShortcutTooltip
          label="Shuffle card"
          shortcut={""}
          position="right"
        >
          <InspoToolbarActionIcon
            show={true}
            icon={<IconReplace />}
            onClick={handleOnChangeCard}
            buttonProps={{
              disabled: !overlayButtonConditions.shuffleCard,
              c: !overlayButtonConditions.shuffleCard ? "gray.4" : "black",
            }}
          />
        </InspoKeyboardShortcutTooltip>

        {overlayButtonConditions.downloadImageOfCard && (
          <InspoKeyboardShortcutTooltip
            label="Download card snapshot"
            shortcut={""}
            position="right"
          >
            <InspoToolbarActionIcon
              show={true}
              icon={<IconPhotoDown />}
              onClick={onDownloadImageOfCard}
            />
          </InspoKeyboardShortcutTooltip>
        )}

        {overlayButtonConditions.shuffleImages && (
          <InspoKeyboardShortcutTooltip
            label="Shuffle card image"
            shortcut={""}
            position="right"
          >
            <InspoToolbarActionIcon
              show={true}
              buttonProps={{
                disabled: imgLocked,
                c: imgLocked ? "gray.4" : "black",
              }}
              icon={<IconArrowsShuffle />}
              onClick={() => {
                document.dispatchEvent(new Event(InspoEvent.OPEN_LOGIN_MODAL));
                onChangeImage();
              }}
            />
          </InspoKeyboardShortcutTooltip>
        )}

        {overlayButtonConditions.lockImage && (
          <InspoKeyboardShortcutTooltip
            label={"Lock card image"}
            shortcut={""}
            position="right"
          >
            <InspoToolbarActionIcon
              buttonProps={{
                variant: imgLocked ? "filled" : "transparent",
                c: imgLocked ? "white" : "black",
              }}
              show={true}
              icon={<IconLock />}
              onClick={() => {
                document.dispatchEvent(new Event(InspoEvent.OPEN_LOGIN_MODAL));
                onLockImage();
              }}
            />
          </InspoKeyboardShortcutTooltip>
        )}

        {overlayButtonConditions.uploadImage && (
          <InspoKeyboardShortcutTooltip
            label="Upload your own image"
            shortcut={""}
            position="right"
          >
            <InspoToolbarActionIcon
              show={true}
              icon={<IconPhotoUp />}
              onClick={onUploadImage}
              buttonProps={{
                loading: isUploading,
                disabled: isUploading,
              }}
            />
          </InspoKeyboardShortcutTooltip>
        )}
      </Vertical>
    </Vertical>
  );
}
