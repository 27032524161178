import { Badge, Title } from "@mantine/core";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import {
  customizeTextVariantIfNeededForCardPreview,
  type CardPreviewCustomizationProps,
  type InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";

type TextLinesWithUpdatesTagContentProps = {
  titles: string[];
  badges: string[];
};

export const textLinesWithUpdatesTagContent = {
  fashion: {
    titles: [
      "New Arrivals",
      "Collections",
      "Size Guide",
      "Sustainability",
      "Look book",
    ],
    badges: ["45"],
  },
  food: {
    titles: [
      "Today's Specials",
      "Menu Items",
      "Dietary Guide",
      "Ingredients",
      "Recipe Book",
    ],
    badges: ["12"],
  },
  beauty: {
    titles: [
      "Products",
      "Skincare",
      "Ingredients",
      "Revive",
      "Serum",
    ],
    badges: ["23"],
  },
  editorial: {
    titles: [
      "Latest Stories",
      "Features",
      "Categories",
      "Contributors",
      "Archives",
    ],
    badges: ["8"],
  },
  finance: {
    titles: ["Market", "Investments", "Analysis", "Portfolio", "Reports"],
    badges: ["15"],
  },
  sport: {
    titles: [
      "Training Plans",
      "Equipment",
      "Nutrition",
      "Performance",
      "Coaching",
    ],
    badges: ["34"],
  },
} as const satisfies Record<string, TextLinesWithUpdatesTagContentProps>;

export default function TextLinesWithUpdatesTag({
  colorScheme,
  content,
  titleFontFamily,
  bodyFontFamily,
  ...props
}: InspoTypeCardProps<TextLinesWithUpdatesTagContentProps> &
  BentoCardChildrenProps &
  CardPreviewCustomizationProps) {
  if (!content) return null;

  return (
    <Vertical
      fullW
      fullH
      center
      px="md"
      gap={"xs"}
      bg={colorScheme.background}
      {...props}
    >
      {content.titles.map((title, i) => (
        <Horizontal key={i} fullW gap={"xs"} noWrap>
          <Title
            variant={customizeTextVariantIfNeededForCardPreview(
              "card-headline-sm",
              titleFontFamily,
            )}
            ff={titleFontFamily}
            c={colorScheme.textdark}
            ta={"start"}
          >
            {title}
          </Title>
          {content.badges[i] && (
            <Badge bg={colorScheme.ctadark} c={colorScheme.textlight}>
              {content.badges[i]}
            </Badge>
          )}
        </Horizontal>
      ))}
    </Vertical>
  );
}
