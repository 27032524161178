import {
  BackgroundImage,
  Overlay,
  Text,
  Title,
  type CSSProperties,
} from "@mantine/core";
import type {
  BentoCardChildrenProps,
  InspoTypeCardProps,
} from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Vertical } from "../mantine-layout-components/Vertical";
import { OverlayedBackgroundImage } from "../ui/InspoOverlayedBackgroundImage";

type HeadingWithBgPhotoLeftContentProps = {
  titleup: string;
  titledown: string;
  subtitle?: string;
  description: string;
  additionalSpacingBetweenTitleAndSubtitle: boolean;
  imgs: string[];
  alt: string;
  align: "center" | "bottomLeft";
};

export const headingWithBgPhotoLeftContent = {
  fashion: {
    titleup: "Refined",
    titledown: "Apparel",
    subtitle:
      "Exploring the intersection of sustainable fashion\nand contemporary design aesthetics",
    description: "prova",
    additionalSpacingBetweenTitleAndSubtitle: false,
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_landscape",
    }),
    alt: "Fashion Image",
    align: "center",
  },
  food: {
    titleup: "Culinary",
    titledown: "Innovation",
    subtitle:
      "Discovering the perfect balance between traditional techniques\nand modern gastronomy experiments",
    description: "prova",
    additionalSpacingBetweenTitleAndSubtitle: false,
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_landscape",
    }),
    alt: "Culinary Innovation Image",
    align: "center",
  },
  beauty: {
    titleup: "Natural",
    titledown: "Radiance",
    subtitle:
      "Combining cutting-edge dermatological research\nwith nature's most powerful ingredients",
    description: "prova",
    additionalSpacingBetweenTitleAndSubtitle: false,
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_landscape",
    }),
    alt: "Beauty Product Image",
    align: "center",
  },
  editorial: {
    titleup: "Digital",
    titledown: "Stories",
    subtitle:
      "Where compelling narratives meet innovative storytelling\nreshaping the future of digital content",
    description: "prova",
    additionalSpacingBetweenTitleAndSubtitle: false,
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_landscape",
    }),
    alt: "Editorial Content Image",
    align: "center",
  },
  finance: {
    titleup: "Smart",
    titledown: "Banking",
    subtitle:
      "Transforming traditional financial services with AI\nand blockchain-powered solutions",
    description: "prova",
    additionalSpacingBetweenTitleAndSubtitle: false,
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_landscape",
    }),
    alt: "Financial Technology Image",
    align: "center",
  },
  sport: {
    titleup: "Peak",
    titledown: "Performance",
    subtitle:
      "Pushing the boundaries of human potential through\nadvanced sports science and technology",
    description: "prova",
    additionalSpacingBetweenTitleAndSubtitle: false,
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_landscape",
    }),
    alt: "Sports Technology Image",
    align: "center",
  },
} as const satisfies Record<string, HeadingWithBgPhotoLeftContentProps>;

export default function HeadingWithBgPhoto({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<HeadingWithBgPhotoLeftContentProps> &
  BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  const mainAlignmentStyle: Record<
    HeadingWithBgPhotoLeftContentProps["align"],
    CSSProperties
  > = {
    center: {
      alignItems: "center",
      padding: "0 10%",
    },
    bottomLeft: {
      justifyContent: "flex-end",
      alignItems: "flex-start",
    },
  };

  const textAlignmentStyle: Record<
    HeadingWithBgPhotoLeftContentProps["align"],
    CSSProperties
  > = {
    center: {
      textAlign: "center",
    },
    bottomLeft: {
      textAlign: "left",
    },
  };

  return (
    <OverlayedBackgroundImage
      src={uploadedImagePath ?? content.imgs[imgIndex]}
      style={{
        objectFit: "cover",
        aspectRatio: "1/1",
      }}
      radius={"xxs"}
      w={"100%"}
      h={"100%"}
      {...props}
    >
      <Vertical
        fullH
        spaceBetween
        pos={"relative"}
        px={"lg"}
        py={"lg"}
        style={{
          ...mainAlignmentStyle[content.align],
        }}
      >
        <Title
          variant="card-display-lg"
          c={colorScheme.textcontrast}
          ta={textAlignmentStyle[content.align].textAlign}
        >
          {content.titleup}
        </Title>

        <Text variant="card-label-lg" c={colorScheme.textcontrast} ta="center">
          {content.subtitle}
        </Text>

        <Title
          variant="card-display-lg"
          c={colorScheme.textcontrast}
          ta={textAlignmentStyle[content.align].textAlign}
        >
          {content.titledown}
        </Title>
      </Vertical>
    </OverlayedBackgroundImage>
  );
}
  