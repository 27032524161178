import { Text, Title } from "@mantine/core";
import { IconChevronRight, IconMailbox, IconSend } from "@tabler/icons-react";
import { Horizontal } from "./mantine-layout-components/Horizontal";
import { Vertical } from "./mantine-layout-components/Vertical";
import { InspoCreatedBy } from "./ui/InspoCreatedBy";
import { modals, type ContextModalProps } from "@mantine/modals";
import { EmailInput } from "./EmailInput";
import { ModalKind } from "../utils";

export function openModalUpdates({ isMobile }: { isMobile: boolean }) {
  modals.openContextModal({
    modal: ModalKind.UPDATES,
    innerProps: {},
    fullScreen: isMobile,
    onClose: () => modals.close(ModalKind.UPDATES),
    size: "lg",
    centered: true,
    trapFocus: false,
    styles: {
      header: {
        backgroundColor: "transparent",
      },
      content: {
        borderRadius: isMobile ? undefined : "var(--lg, 24px)",
        backgroundColor: isMobile
          ? "rgba(235, 235, 235, 1)"
          : "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(15px)",
      },
    },
  });
}

export const ModalUpdates = ({}: ContextModalProps<{}>) => {
  const data = {
    title: "Your  typography inspiration updated every week",
    subtitle:
      "Subscribe for a dose of typographic inspiration and first dibs on tool updates. ",
  };

  return (
    <Vertical fullW fullH gap={"lg"} center>
      <Vertical gap={"sm"} style={{ textAlign: "center" }} maw={"400px"}>
        <IconMailbox size={24} style={{ margin: "auto" }} />
        <Title w={"100%"} c={"black"} variant="headline-xs">
          {data.title}
        </Title>
        <Text c={"#1C1B1F"} variant="paragraph-lg">
          {data.subtitle}
        </Text>
      </Vertical>
      <EmailInput onClick={() => modals.closeAll()} />

      <InspoCreatedBy />

      <Horizontal
        fullW
        gap={"lg"}
        p={"md"}
        noWrap
        spaceBetween
        style={{
          borderRadius: "var(--mantine-radius-xs)",
          backgroundColor: "rgba(25, 25, 25, 0.7)",
          backdropFilter: "blur(15px)",
          cursor: "pointer",
        }}
        onClick={() => {
          window.open("https://tally.so/r/wa2Z5y", "_blank");
        }}
      >
        <Horizontal noWrap>
          <Horizontal
            w={50}
            h={50}
            center
            noWrap
            bg={"#6C6C6C"}
            style={{ borderRadius: "var(--mantine-radius-xs)" }}
          >
            <IconSend color="white" size={20} />
          </Horizontal>
          <Vertical gap={"xs"}>
            <Text c={"white"} variant="paragraph-lg">
              Help us improve InspoType
            </Text>
            <Text c="rgba(255, 255, 255, 0.60)" variant="paragraph-md">
              Send us a feedback about your experience
            </Text>
          </Vertical>
        </Horizontal>
        <Vertical h={"100%"} center>
          <IconChevronRight color="white" size={20} />
        </Vertical>
      </Horizontal>
    </Vertical>
  );
};
