import { Image, Text, Title } from "@mantine/core";
import type { BentoCardChildrenProps, InspoTypeCardProps } from "src/utils/colorScheme/palette";
import {
  cloudinaryUrls,
  images,
} from "../../../../utils/images/cloudinaryImages";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import { Vertical } from "../mantine-layout-components/Vertical";

type HeaderHalfContentProps = {
  title: string;
  subtitle: string;
  label1: string;
  label2: string;
  label3: string;
  imgs: string[];
  alt: string;
  align: "center" | "bottomLeft";
};

export const HeaderHalfContent = {
  fashion: {
    title: "Store\nCollection\nInspiration",
    subtitle: "About\nShop\nServices",
    label1: "Instagram", 
    label2: "Tiktok", 
    label3: "Twitter", 
    imgs: cloudinaryUrls(images.fashion, {
      folder: "fashion",
      transformation: "bento_square",
    }),
    alt: "Product Image",
    align: "bottomLeft",
  },

   beauty: {
    title: "Glow\nNourish\nTransform",
    subtitle: "Skincare\nMakeup\nWellness",
    label1: "Sephora", 
    label2: "Glossier", 
    label3: "Fenty Beauty", 
    imgs: cloudinaryUrls(images.beauty, {
      folder: "beauty",
      transformation: "bento_square",
    }),
    alt: "Beauty Product Image",
    align: "bottomLeft",
  },
  food: {
    title: "Savor\nSip\nIndulge",
    subtitle: "Recipes\nRestaurants\nBeverages",
    label1: "Bon Appétit", 
    label2: "Wine Spectator", 
    label3: "Yelp", 
    imgs: cloudinaryUrls(images.food, {
      folder: "food",
      transformation: "bento_square",
    }),
    alt: "Food and Drink Image",
    align: "bottomLeft",
  },

  editorial: {
    title: "Read\nDiscover\nEngage",
    subtitle: "News\nOpinion\nFeatures",
    label1: "The New Yorker", 
    label2: "The Atlantic", 
    label3: "Vox", 
    imgs: cloudinaryUrls(images.editorial, {
      folder: "editorial",
      transformation: "bento_square",
    }),
    alt: "Editorial Image",
    align: "bottomLeft",
  },

  finance: {
    title: "Invest\nGrow\nSecure",
    subtitle: "Stocks\nCrypto\nRetirement",
    label1: "Bloomberg", 
    label2: "WSJ", 
    label3: "Morningstar", 
    imgs: cloudinaryUrls(images.finance, {
      folder: "finance",
      transformation: "bento_square",
    }),
    alt: "Finance Image",
    align: "bottomLeft",
  },

  sport: {
    title: "Train\nCompete\nWin",
    subtitle: "Fitness\nTeam Sports\nAthletics",
    label1: "ESPN", 
    label2: "Nike", 
    label3: "Strava", 
    imgs: cloudinaryUrls(images.sport, {
      folder: "sport",
      transformation: "bento_square",
    }),
    alt: "Sports Image",
    align: "bottomLeft",
  },

} as const satisfies Record<string, HeaderHalfContentProps>;

export default function HeaderHalf({
  colorScheme,
  content,
  imgIndex,
  uploadedImagePath,
  ...props
}: InspoTypeCardProps<HeaderHalfContentProps> & BentoCardChildrenProps) {
  if (!content || imgIndex === undefined) return null;

  return (
    <Horizontal
    fullW
    fullH
    spaceBetween
    bg={colorScheme.background}
    gap={0}
    noWrap
    {...props}
  >
    <Vertical
      gap={"xs"}
      px={"lg"}
      py={"lg"}
      maw={"50%"}
      fullH
      spaceBetween
    >
      <Title
        variant="card-headline-md"
        c={colorScheme.textdark}
        ta={"left"}
        w={"100%"}
      >
        {content.title}
      </Title>
 
      <Text
        variant="card-paragraph-md"
        c={colorScheme.textdark}
      >
        {content.subtitle}
      </Text>
    <Horizontal>
    <Text 
        variant="card-label-sm" 
        c={colorScheme.textdark} 
        ta={"left"}
    >
        {content.label1}
      </Text>
      <Text 
        variant="card-label-sm" 
        c={colorScheme.textdark}  
        ta={"left"}
    >
        {content.label2}
      </Text>
      <Text 
        variant="card-label-sm" 
        c={colorScheme.textdark} 
        ta={"left"}
    >
        {content.label3}
      </Text>
    </Horizontal>
    </Vertical>
    <Image
      src={uploadedImagePath ?? content.imgs[imgIndex]}
      alt={content.alt}
      fit="cover"
      h={"100%"}
      w={"60%"}
    />
  </Horizontal>
);
}